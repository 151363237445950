import React, { Component } from "react";
import { Link } from "react-router-dom";

import b_xl_1 from "../../../assets/images/blog/blog-xl-1.png";
import b_xl_2 from "../../../assets/images/blog/blog-xl-2.png";
import b_xl_3 from "../../../assets/images/blog/blog-xl-3.png";
import b_xl_4 from "../../../assets/images/blog/blog-xl-4.png";
import b_xl_5 from "../../../assets/images/blog/blog-xl-5.png";

import b_sm_1 from "../../../assets/images/blog/b-sm1.png";
import b_sm_2 from "../../../assets/images/blog/b-sm2.png";
import b_sm_3 from "../../../assets/images/blog/b-sm3.png";
import b_sm_4 from "../../../assets/images/blog/b-sm4.png";
import b_sm_5 from "../../../assets/images/blog/b-sm5.png";

import sidebarBanner from "../../../assets/images/sidebar-banner.png"
import { getOrder } from "../../../services/homepage"
import moment from "moment";
import host_url from "../../../services/environment";
import cookieCutter from 'cookie-cutter';

class Booking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: cookieCutter.get('lang') ? cookieCutter.get('lang') : 'M',
            data: {}
        }
        const queryParams = new URLSearchParams(window.location.search);
        getOrder(queryParams.get("ordid"),this.state.lang)
            .then(resp => {
                this.setState({ data: resp[0] });
            });

    }

    render() {

        const redirecttopg = () => {
            window.location.href = `${host_url}/api/v1/generatelink?ord_id=${this.state?.data?.SNAP_ID}`;
        }
        const downloadlink = () => {
            window.location.href = `${host_url}/api/v1/getOrder?orderid=${this.state?.data?.SNAP_ID}&pdf=true`;
        }


        return (
            <>
                {/* ===============  breadcrumb area start =============== */}
                <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="breadcrumb-wrap">
                                    <h2>{this.state.lang == 'M' ? 'बुकिंग पुष्टीकरण पृष्ठ' : 'Booking Confirmation Page'}</h2>
                                    <ul className="breadcrumb-links">
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/`}>{this.state.lang == 'M' ? "मुख्यपृष्ठ" : "Home"}</Link>
                                            <i className="bx bx-chevron-right" />
                                        </li>
                                        {/* <li>Blog Standard</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ===============  breadcrumb area end =============== */}
                {/* ===============  Blog wrapper area start =============== */}

                {this.state.data.BOOKING_AMOUNT &&
                    <div className="blog-standard-wrapper pt-90">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-11 col-md-7">
                                    <div className="row">
                                        
                            
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="blog-card-xl">
                                                <div className="blog-title">
                                                    {this.state?.data?.PAYMENT_STATUE == '2' ? (this.state.lang == 'M' ? 'तुमचे बुकिंग कन्फर्म झाले आहे.' :"Your Booking has been Confirmed" ): (this.state.lang == 'M' ? 'तुमचे बुकिंग यशस्वी झाले नाही.' :"Your Booking has been Failed.")}
                                                </div>


                                                <div className="blog-details">
                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? 'स्नॅप आयडी' : 'Snap Id'}</b>SNAP{this.state?.data?.ORDER_ID}/{this.state?.data?.SNAP_ID}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>
                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? 'पॅकेजचे नाव' :'Package Name'}</b>{this.state?.data?.PACKAGE_NAME} ({" "} {this.state?.data?.TYPE} {" "})
                                                    {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <div className="blog-card-xl">
                                                <div style={{ margin: "14px" }} className="blog-title">
                                                   {this.state.lang=='M' ? 'पेमेंट तपशील:' : 'Payment details:' }
                                            </div>


                                                <div className="blog-details">
                                                    <div className="blog-info">
                                                        <b>{this.state.lang=='M' ? 'बुकिंग रक्कम:' : 'Booking Amount:'}</b>{converttocomma(this.state?.data?.BOOKING_ACTUAL_PRICE)}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>
                                                    {this.state?.data?.PAYMENT_STATUE == '2' ?
                                                        <>
                                                            <div className="blog-info">
                                                                <b>{this.state.lang=='M' ? 'देय रक्कम:' : 'Paid Amount:'}</b>{converttocomma(this.state?.data?.BOOKING_AMOUNT)}
                                                                {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>{this.state.lang=='M' ? 'बुकिंगच्या 1 दिवसापूर्वी देय असलेली उर्वरित रक्कम' : 'Remaining amount due' }</b> {converttocomma(this.state?.data?.BOOKING_REMAINIG_AMOUNT)}
                                                                {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                        </> : <>
                                                            <div style={{ color: "red" }} className="blog-info">
                                                                <b>{this.state.lang=='M' ? 'पेमेंट अयशस्वी' : 'Payment Failed'}</b>
                                                                {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                        </>}
                                                    <div className="blog-btn">

                                                        {this.state?.data?.PAYMENT_STATUE == '2' && this.state?.data?.BOOKING_REMAINIG_AMOUNT > 0 ?
                                                            <button style={{ margin: "10px" }} className="btn btn-outline-primary" onClick={redirecttopg} type="button">
                                                                Pay Now {converttocomma(this.state?.data?.BOOKING_REMAINIG_AMOUNT)} <i className="bx bx-log-in-circle" />
                                                            </button> : <></>
                                                        }
                                                        {/* {this.state?.data?.PAYMENT_STATUE == '2' && <button className="btn btn-outline-primary" onClick={downloadlink} type="button">
                                                            Download Voucher
                                                        <i className="bx bx-log-in-download" />
                                                        </button>
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>

                                                    <hr></hr>
                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? 'बुकिंग तारीख' :"Booking Date"}</b> {moment(this.state?.data?.BOOKING_DATE).format("DD-MMM-YYYY")}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>

                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? 'लोकेशन' :"Location"} </b>Snap City PM99+2H, Talegaon Dabhade, Maharashtra 410506
                                                    {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>
                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? 'पूर्ण नाव' :"Full Name"}</b>{this.state?.data?.FULL_NAME}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>
                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? 'ई - मेल आयडी' :"Email Id"}</b>{this.state?.data?.EMAIL_ID}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>
                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? "फोन नंबर" : "Phone Number"}</b>{this.state?.data?.PHONE_NUMBER}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>
                                                    <div className="blog-info">
                                                        <b>{ this.state.lang == 'M' ? "Adults" : "Number Of Person"}</b>{this.state?.data?.ADULTS}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>

                                                    {this.state?.data?.IS_MAKEUP == '1' &&

                                                        <>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>{ this.state.lang == 'M' ? "मेकअप आर्टिस्ट":"Make up Artist"}</b>{ this.state.lang == 'M' ? "समाविष्ट" :"Included"}
                                                    </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>{ this.state.lang == 'M' ? "मेकअप आर्टिस्ट माहिती": "Make up Artist Info"} </b> {this.state.data?.MAKEUP_INFO?.packageName}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>{ this.state.lang == 'M' ? "मेकअप आर्टिस्ट नाव":"Make up Artist Name"} </b> {this.state.data?.MAKEUP_INFO?.MAKUP_ART_NAME == null ? 'Still not allocated' : this.state.data?.MAKEUP_INFO?.MAKUP_ART_NAME}
                                                            </div>
                                                        </>
                                                    }
                                                    <hr></hr>
                                                    <div className="tab-content-1">
                                                        {/* <div className="p-overview">
                                                            <h5>Overview</h5>
                                                            <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus. In non enim libero.Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</p>
                                                        </div> */}
                                                        <div className="p-highlight">
                                                            <h5>{ this.state.lang == 'M' ? "पॅकेज तपशील:": "Package Details:"} </h5>
                                                            <br></br>
                                                            <ul>
                                                                {this.state?.data?.PACKAGE_DTL?.map((d, index) =>
                                                                    <li><p><i className="bx bx-chevron-right" /> {d.DETAIL}</p></li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-7">
                                </div>


                            </div>
                        </div>
                    </div>
                }
                {/* ===============  Blog wrapper area end =============== */}
            </>

        );
    }
}
function converttocomma(str) {
    console.log(str)
    if (str) {
        return "₹ " + str.toLocaleString();
    } return "₹ " + 0;
}
export default Booking;
