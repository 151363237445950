import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import host_url from "../../../services/environment";
class MainBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {

        const mainBannerOptions = {
            items: 1,
            loop: true,
            margin: 0,
            smartSpeed: 1000,
            dots: false,
            nav: true,
            autoplay: 6000,
            autoplayTimeout: 6000,
            autoplayHoverPause: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            navText: ["<i class='bx bx-chevron-left' ></i>", "<i class='bx bx-chevron-right'></i>"],
            responsive: {
                0: {
                    items: 1,
                    nav: false,
                    dots: false
                },
                600: {
                    items: 1,
                    nav: false,
                    dost: false,
                },
                1000: {
                    items: 1,
                    nav: true,
                    loop: true
                },
            }
        };
        return (
            <>
                {/* ===============  Main banner area start =============== */}
                <div className="main-banner" style={{margin:"20px"}}>


                    {/* {this.props?.bannerdata1 && */}
                        <OwlCarousel className="banner-slider owl-carousel"  {...mainBannerOptions}>
                            {/* {this.props?.bannerdata1?.map((d, index) => ( */}
                                <div onClick={openWhatsapp}  className="slider-item slider-item-1 " style={
                                    {
                                        backgroundImage: "url("+host_url+"/fetchimage?type=Banner&name=middlebanner.jpg) ",
                                        // backgroundPosition: 'center',
                                        cursor:"pointer",
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }
                                }>
                                    <div className="container">
                                        <div className="slider-content wow fadeInLeft animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                                            {/* <h2>{d.TEXT} </h2> */}
                                            <div className="banner-btn">
                                                {/* <Link to={`${process.env.PUBLIC_URL}/package-details?pkg=1`} className="btn-common-sm">{this.props.lang=='M' ? "पूर्ण दिवसाचे पॅकेज": "Full Day Package"}</Link> */}
                                                {/* <Link style={{margin:"10px"}} to={`${process.env.PUBLIC_URL}/package-details?pkg=3`} className="btn-common-sm">{this.props.lang=='M' ? "अर्धा दिवसाचे पॅकेज": "Half Day Package"}</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* ))} */}
                        </OwlCarousel>
                    {/* } */}
                </div>
            </>
        );
    }
}
const openWhatsapp = () => {
    const phoneNumber = '7758862637'; // Replace with your WhatsApp number in international format
    const message = 'Hello, I need rental costume'; // Optional predefined message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  
    // Open the WhatsApp chat in a new tab
    window.open(url, '_blank');
};
export default MainBanner;
