import React, { Component } from "react";
import { Link } from "react-router-dom";

import host_url from "../../../services/environment";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import minicity1 from "../../../assets/images/sets/1MiniCity/1.jpg";
import minicity2 from "../../../assets/images/sets/1MiniCity/2.jpg";
import minicity3 from "../../../assets/images/sets/1MiniCity/3.jpg";
import minicity4 from "../../../assets/images/sets/1MiniCity/4.jpg";

import r1 from "../../../assets/images/sets/2RajasthaniHaveli/1.jpg";
import r2 from "../../../assets/images/sets/2RajasthaniHaveli/2.jpg";
import r3 from "../../../assets/images/sets/2RajasthaniHaveli/3.jpg";
import r4 from "../../../assets/images/sets/2RajasthaniHaveli/4.jpg";
import r5 from "../../../assets/images/sets/2RajasthaniHaveli/5.jpg";
import r6 from "../../../assets/images/sets/2RajasthaniHaveli/6.jpg";

import j1 from "../../../assets/images/sets/3Jaipurtheme/1.jpg";
import j2 from "../../../assets/images/sets/3Jaipurtheme/2.jpg";
import j3 from "../../../assets/images/sets/3Jaipurtheme/3.jpg";
import j4 from "../../../assets/images/sets/3Jaipurtheme/4.jpg";

import n1 from "../../../assets/images/sets/3Natur/1.jpg";
import n2 from "../../../assets/images/sets/3Natur/2.jpg";
import n3 from "../../../assets/images/sets/3Natur/3.jpg";
import n4 from "../../../assets/images/sets/3Natur/4.jpg";



import l1 from "../../../assets/images/sets/4Library/1.jpg";
import l2 from "../../../assets/images/sets/4Library/2.jpg";
import l3 from "../../../assets/images/sets/4Library/3.jpg";
import l4 from "../../../assets/images/sets/4Library/4.jpg";

import g1 from "../../../assets/images/sets/5GlassTent/1.jpg";
import g2 from "../../../assets/images/sets/5GlassTent/2.jpg";
import g3 from "../../../assets/images/sets/5GlassTent/3.jpg";
import g4 from "../../../assets/images/sets/5GlassTent/4.jpg";

import w1 from "../../../assets/images/sets/6WadaFront/1.jpg";
import w2 from "../../../assets/images/sets/6WadaFront/2.jpg";
import w3 from "../../../assets/images/sets/6WadaFront/3.jpg";
import w4 from "../../../assets/images/sets/6WadaFront/4.jpg";

import pi1 from "../../../assets/images/sets/7PianoRoom/1.jpg";
import pi2 from "../../../assets/images/sets/7PianoRoom/2.jpg";
import pi3 from "../../../assets/images/sets/7PianoRoom/3.jpg";
// import pi4 from "../../../assets/images/sets/7PianoRoom/4.jpg";

import ni1 from "../../../assets/images/sets/8NightRomanticSet/1.jpg";
import ni2 from "../../../assets/images/sets/8NightRomanticSet/2.jpg";
import ni3 from "../../../assets/images/sets/8NightRomanticSet/3.jpg";

import k1 from "../../../assets/images/sets/9Kitchen/1.jpg";
import k2 from "../../../assets/images/sets/9Kitchen/2.jpg";
import k3 from "../../../assets/images/sets/9Kitchen/3.jpg";
import k4 from "../../../assets/images/sets/9Kitchen/4.jpg";

 


class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const destinationsOptions = {
      items: 1,
      loop: true,
      margin: 0,
      smartSpeed: 1000,
      dots: true,
      nav: false,
      autoplay: 6000,
      autoplayTimeout: 6000,
      autoplayHoverPause: true,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
      navText: [
        "<i class='bx bx-chevron-left' ></i>",
        "<i class='bx bx-chevron-right'></i>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
        },
        600: {
          items: 1,
          nav: false,
          dost: false,
        },
        1000: {
          items: 1,
          nav: true,
          loop: true,
        },
      },
    };
    return (
      <>
        {/* ===============  Blog area start =============== */}
        
        <div className="destinations-area pt-105">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="section-head pb-40">
                  {/* <h5>Choose Your Constumes </h5> */}
                  <h5>
                      {this.props.lang == "M" ? "स्नॅप सिटीचे" : "Snap City's"}
                    </h5>
                    <h2>
                      {this.props.lang == "M"
                        ? "मंत्रमुग्ध करणारा सेट"
                        : "Mesmerising Set"}
                    </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={minicity1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={minicity2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={minicity3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={minicity4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={r1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={r2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={r3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={r4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={r5} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={r6} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={j1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={j2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={j3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={j4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={n1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={n2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={n3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={n4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>

            


            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={l1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={l2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={l3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={l4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={g1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={g2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={g3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={g4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                  </OwlCarousel>
                </div>
              </div>
            </div>





            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={w1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={w2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={w3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={w4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={pi1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={pi2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={pi3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                  </OwlCarousel>
                </div>
              </div>
            </div>





            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={ni1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={ni2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={ni3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div>
                  <OwlCarousel
                    className="row owl-carousel destinations-1"
                    {...destinationsOptions}
                  >
                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={k1} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={k2} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={k3} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                    <div className="package-card">
                      <div className="package-thumb">
                        <Link>
                          <img src={k4} alt="" className="img-fluid" />
                        </Link>
                      </div>
                    </div>

                  </OwlCarousel>
                </div>
              </div>
            </div>









          </div>
        </div>
        {/* ===============  Blog area end =============== */}
      </>
    );
  }
}

export default Blog;
