import React, { Component } from "react";
import { Link } from "react-router-dom";

import pd_thumb from "../../../assets/images/package/P2.png";
import pr_1 from "../../../assets/images/package/pr-1.png";

import gallery1Img from "../../../assets/images/gallary/gl-1.png";
import gallery2Img from "../../../assets/images/gallary/gl-2.png";
import gallery4Img from "../../../assets/images/gallary/gl-4.png";
import gallery5Img from "../../../assets/images/gallary/gl-5.png";
import gallery6Img from "../../../assets/images/gallary/gl-6.png";

import galleryGxx1Img from "../../../assets/images/gallary/g-xxl-1.png";
import galleryGxx2Img from "../../../assets/images/gallary/g-xxl-2.png";
import galleryGxx3Img from "../../../assets/images/gallary/g-xxl-3.png";

import gallery17Img from "../../../assets/images/gallary/gl-17.png";
import gallery16Img from "../../../assets/images/gallary/gl-16.png";
import gallery14Img from "../../../assets/images/gallary/gl-14.png";

import galleryGxl1Img from "../../../assets/images/gallary/g-xl-1.png";
import galleryGxl2Img from "../../../assets/images/gallary/g-xl-2.png";
import galleryGxl3Img from "../../../assets/images/gallary/g-xl-3.png";
import galleryGxl4Img from "../../../assets/images/gallary/g-xl-4.png";

import pm_sm_1 from "../../../assets/images/package/p-sm-1.png";
import pm_sm_4 from "../../../assets/images/package/p-sm-4.png";
import pm_sm_2 from "../../../assets/images/package/p-sm-2.png";
import pm_sm_3 from "../../../assets/images/package/p-sm-3.png";

import organizer from "../../../assets/images/organizer.png";
import sidebarBannar from "../../../assets/images/makeup.jpeg";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getPackages, getOrdersCount } from "../../../services/homepage";

import axios from "axios";
import $ from "jquery";
// import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import host_url from "../../../services/environment";
import cookieCutter from "cookie-cutter";
import moment from "moment";
// import NextNProgress from "nextjs-progressbar";

class PackageDetails extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    const pkgid = queryParams.get("pkg");
    let userinfo = cookieCutter.get("userInfo");
    let lang = cookieCutter.get("lang");
    userinfo = userinfo ? JSON.parse(userinfo)?.resp[0] : {};
    this.state = {
      startDate: new Date(),
      userinfo: userinfo,
      pkgid: pkgid,
      data: {},
      minrange: pkgid == 1 ? 3248 : 3198,
      name: userinfo.NAME ? userinfo.NAME : "",
      email: userinfo.EMAIL_ID ? userinfo.EMAIL_ID : "",
      phno: userinfo.PHONE_NO ? userinfo.PHONE_NO : "",
      msg: "",
      bookingmsg: "",
      setEmailError: "",
      pkgdata: [],
      makeupArtist: [],
      minPrice: 3000,
      adults: 8,
      adults_amt: 0,
      makeupartistprice: 0,
      isTickChecked: false,
      packageId: 0,
      isweekned: false,
      lang: lang,
      showbreakup: false,
      isdiscount: true,
    };
  }
  addadults = (e) => {
    this.setState({ adults: e.target.value });
    this.setState({
      adults_amt: parseInt((parseInt(e.target.value) - 8) * 500),
    });

    var given = moment(this.state.startDate, "YYYY-MM-DD");
    var current = moment().startOf("day");
    if (moment.duration(given.diff(current)).asDays() < -10) {
      this.setState({
        minPrice:
        addservicefee(
          parseInt(this.state.pkgdata[0]?.price_cal) +
          parseInt(this.state.makeupartistprice) +
          parseInt(parseInt((parseInt(e.target.value) - 8) * 500)) +
          parseInt(
            !this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0
          )
          ),
      });
    }else{
        this.setState({
            minPrice: 3000 + calServicefee(
                parseInt(this.state.pkgdata[0]?.price_cal) +
                parseInt(this.state.makeupartistprice) +
                parseInt(parseInt((parseInt(e.target.value) - 8) * 500)) +
                parseInt(
                  !this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0
                )
            ),
          });
    }
  };
  pkgtypefn = (e) => {
    this.setState({ pkgtype: e.target.value });
  };
  changeDatepickerHandeller = (date) => {
    var givenDate = new Date(date);
    if (
      givenDate.toString().substring(0, 3) == "Sat" ||
      givenDate.toString().substring(0, 3) == "Sun"
    ) {
      this.setState({ isweekned: true });
    } else {
      this.setState({ isweekned: false });
    }
    var given = moment(givenDate, "YYYY-MM-DD");
    var current = moment().startOf("day");
    var discountedDate = moment(
      this.state.pkgdata[0]?.DICSOUNT_TILL,
      "YYYY-MM-DD"
    );
    if (discountedDate.diff(given, "days") > 0) {
      this.setState({
        isdiscount: true,
      });
    } else {
      this.setState({
        isdiscount: false,
      });
    }

    if (moment.duration(given.diff(current)).asDays() < -10) {
      this.setState({
        minPrice:
        addservicefee(
          parseInt(this.state.pkgdata[0]?.price_cal) +
          parseInt(this.state.makeupartistprice) +
          parseInt(this.state.adults_amt) +
          parseInt(
            !this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0
          )
        ),
      });
    } else {
      this.setState({
        minPrice: 3000 + calServicefee(
            parseInt(this.state.pkgdata[0]?.price_cal) +
            parseInt(this.state.makeupartistprice) +
            parseInt(this.state.adults_amt) +
            parseInt(
              !this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0
            )
          ),
      });
    }

    this.setState({ startDate: date });

    getOrdersCount({ date: moment(date).format("YYYY-MM-DD") }).then((resp) => {
      this.setState({ bookingmsg: resp.data.left });
    });
  };
  handlePayNow = () => {
    this.scrollTop();
    if ((this.state.email == "")) {
      toast("Please Enter Valid Email Id!");
      this.setState({ setEmailError: "Enter valid Email!" });
    } else {
      displayRazorpay1(
        this.state.pkgid,
        this.state?.minPrice,
        this.state.name,
        this.state.phno,
        this.state.email,
        this.state.msg,
        this.state.makeupartistprice,
        this.state.startDate,
        this.state.isTickChecked,
        this.state.packageId,
        this.state.adults,
        this.state.pkgtype
      );
    }
  };
  checkTickBox = () => {
    var checkBox = document.getElementById("checkTick");
    console.log("event", checkBox.checked);
    this.setState({ isTickChecked: checkBox.checked });
  };
  componentDidMount() {
    this.scrollTop();
    getPackages({ id: this.state.pkgid, lang: this.state.lang }).then(
      (resp) => {
        this.setState({
          pkgdata: resp.packageDetails,
          makeupArtist: resp.makeupArtist,
        });
        this.setState({ minPrice: 3000 + calServicefee(
          parseInt(this.state.pkgdata[0]?.price_cal) +
    parseInt(this.state.adults_amt) +
    parseInt(
      !this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0
    )
        ) });
      }
    );
    getOrdersCount({ date: moment(new Date()).format("YYYY-MM-DD") }).then(
      (resp) => {
        this.setState({ bookingmsg: resp.data.left });
      }
    );
  }

  addMakeupArtist = (makeUpArt) => {
    console.log("makeUpArt", makeUpArt);
    this.setState({
      makeupartistprice: makeUpArt.price,
      packageId: makeUpArt.packageId,
    });
    if (!makeUpArt.makeupListAdded) {
      makeUpArt.makeupListAdded = true;
      toast("Make up artist addons added");
    }
    var given = moment(this.state.startDate, "YYYY-MM-DD");
    var current = moment().startOf("day");
    // if (moment.duration(given.diff(current)).asDays() < -10) {
    //   this.setState({
    //     minPrice:
    //     addservicefee(
    //       parseInt(this.state.pkgdata[0]?.price_cal) +
    //       parseInt(makeUpArt.price) +
    //       parseInt(this.state.adults_amt) +
    //       parseInt(
    //         !this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0
    //       )
    //     ),
    //   });
    // }else{
        this.setState({
            minPrice: 3000 + calServicefee(
                parseInt(this.state.pkgdata[0]?.price_cal) +
          parseInt(makeUpArt.price) +
          parseInt(this.state.adults_amt) +
          parseInt(
            !this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0
          )
              ),
          });
    // }
  };

  removeMakeupArtist = () => {
    this.state.makeupArtist.forEach((element) => {
      element.makeupListAdded = false;
    });
    this.setState({ makeupartistprice: 0, packageId: 0 });
    var given = moment(this.state.startDate, "YYYY-MM-DD");
    var current = moment().startOf("day");
    // if (moment.duration(given.diff(current)).asDays() < -10) {
    //   this.setState({
    //     minPrice:
    //     addservicefee(
    //       parseInt(this.state.pkgdata[0]?.price_cal) +
    //       0 +
    //       parseInt(this.state.adults_amt) +
    //       (!this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0)
    //     ),
    //   });
    // }else{
        this.setState({
            minPrice: 3000 + calServicefee(
                parseInt(this.state.pkgdata[0]?.price_cal) +
          0 +
          parseInt(this.state.adults_amt) +
          (!this.state.isdiscount ? this.state.pkgdata[0]?.DICSOUNT : 0)
              ),
          });
    // }
    toast("Make up artist addons removed.");
  };

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const startDate = this.state.startDate;
    const termPdf =
      host_url + "/fetchterms?name=Terms_Conditions_of_Snapcity.pdf";
    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="breadcrumb-wrap">
                  <h2>
                    {this.state.lang == "M"
                      ? "स्नॅप सिटी पॅकेज तपशील"
                      : "Snap City Package Details"}
                  </h2>
                  <ul className="breadcrumb-links">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        {this.state.lang == "M" ? "मुख्यपृष्ठ" : "Home"}
                      </Link>
                      <i className="bx bx-chevron-right" />
                    </li>
                    <li>
                      {this.state.lang == "M"
                        ? "पॅकेज तपशील"
                        : "Package Details"}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        <div className="package-details-wrapper pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="package-details">
                  <div className="package-thumb">
                    <img
                      src={
                        "https://www.snapcityinpune.com/fetchimage?type=packages&name=" +
                        this.state.pkgdata[0]?.IMG_SRC
                      }
                      alt=""
                    />
                  </div>
                  <div className="package-header">
                    <div className="package-title">
                      <h3>{this.state.pkgdata[0]?.NAME}</h3>
                      {/* <strong><i className="flaticon-arrival" />
                                            Mount Dtna, Spain
                                        </strong> */}
                    </div>
                    {/* <div className="pd-review">
                                            <p>Excellent</p>
                                            <ul>
                                                <li><i className="bx bxs-star" /></li>
                                                <li><i className="bx bxs-star" /></li>
                                                <li><i className="bx bxs-star" /></li>
                                                <li><i className="bx bxs-star" /></li>
                                                <li><i className="bx bx-star" /></li>
                                            </ul>
                                            <p>800 Review</p>
                                        </div> */}
                  </div>
                  <div className="p-short-info">
                    <div className="single-info">
                      <i className="flaticon-clock" />
                      <div className="info-texts">
                        <strong>
                          {" "}
                          {this.state.lang == "M" ? "कालावधी" : "Duration"}
                        </strong>
                        <p>{this.state.pkgdata[0]?.TYPE}</p>
                      </div>
                    </div>
                  </div>
                  <div className="package-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          <i className="flaticon-info" />
                          {this.state.lang == "M" ? "माहिती" : "Information"}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link "
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          <i className="flaticon-clipboard" />
                          {this.state.lang == "M"
                            ? "मेकअप आर्टिस्ट"
                            : "Make up Artist"}
                        </button>
                      </li>

                      {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <i className="flaticon-gallery" />
                                                Our Gallary</button>
                                        </li> */}
                    </ul>
                    <div
                      className="tab-content p-tab-content"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="tab-content-1">
                              {/* <div className="p-overview">
                                                            <h5>Overview</h5>
                                                            <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus. In non enim libero.Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</p>
                                                        </div> */}
                              <div className="p-highlight">
                                <h5>Highlight</h5>
                                <ul>
                                  {this.state.pkgdata.map((d, index) => (
                                    <li>
                                      <i className="bx bx-circle" />{" "}
                                      <p>{d.DETAIL}</p>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade  "
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="tab-content-2">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="p-timeline-overview">
                                <h5>
                                  {" "}
                                  {this.state.lang == "M"
                                    ? "मेकअप आर्टिस्ट निवडा"
                                    : "Add Makeup Artist"}
                                </h5>
                                {/* <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus.</p> */}
                              </div>
                              <ul className="p-timeline">
                                {this.state.makeupArtist.map((d, index) => (
                                  <li>
                                    <div className="timeline-index">
                                      <div className="index-circle">
                                        <h5>{index + 1}</h5>
                                      </div>
                                    </div>
                                    <div className="timeline-content">
                                      <h5>{d.packageName}</h5>
                                      <strong>
                                        ₹ {d.price.toLocaleString()}
                                      </strong>
                                      <div
                                        onClick={(e) => this.addMakeupArtist(d)}
                                        className="sidebar-banner-btn"
                                      >
                                        <button
                                          className={`${
                                            d.makeupListAdded
                                              ? "btn btn-outline-primary makeUpListSel"
                                              : "btn btn-outline-primary"
                                          }`}
                                          disabled={
                                            d.makeupListAdded ? true : false
                                          }
                                          type="button"
                                        >
                                          <i className="bx bx-" />
                                          Add Make Up Artistis
                                        </button>
                                      </div>
                                      {/* <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla.</p> */}
                                      <ul>
                                        {d.features.map((a, index) => (
                                          <>
                                            <li />
                                            <li>
                                              <i className="bx bx-check" />
                                              {a}
                                            </li>
                                          </>
                                        ))}
                                      </ul>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="tab-contant-3">
                            <div className="row">
                              <div className="col-lg-8 col-md-8">
                                <div className="package-grid-one">
                                  <div className="single-grid">
                                    <Link
                                      to={gallery1Img}
                                      className="g-img-sm-1 main-gallary"
                                    >
                                      <img
                                        src={gallery1Img}
                                        alt="gallary-img"
                                      />
                                    </Link>

                                    <Link
                                      to={gallery2Img}
                                      className="g-img-sm-2 main-gallary"
                                    >
                                      <img
                                        src={gallery2Img}
                                        alt="gallary-img"
                                      />
                                    </Link>

                                    <Link
                                      to={galleryGxx1Img}
                                      className="g-img-md main-gallary"
                                    >
                                      <img
                                        src={galleryGxx1Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                  </div>
                                  <div className="single-grid mt-24">
                                    <Link
                                      to={gallery2Img}
                                      className="g-img-sm-1 main-gallary"
                                    >
                                      <img
                                        src={gallery2Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                    <Link
                                      to={gallery4Img}
                                      className="g-img-sm-2 main-gallary"
                                    >
                                      <img
                                        src={gallery4Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                    <Link
                                      to={galleryGxx2Img}
                                      className="g-img-md main-gallary"
                                    >
                                      <img
                                        src={galleryGxx2Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                  </div>

                                  <div className="single-grid mt-24">
                                    <Link
                                      to={gallery5Img}
                                      className="g-img-sm-1 main-gallary"
                                    >
                                      <img
                                        src={gallery5Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                    <Link
                                      to={gallery6Img}
                                      className="g-img-sm-2 main-gallary"
                                    >
                                      <img
                                        src={gallery6Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                    <Link
                                      to={galleryGxx3Img}
                                      className="g-img-md main-gallary"
                                    >
                                      <img
                                        src={galleryGxx3Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4">
                                <div className="package-grid-two">
                                  <div className="single-grid-2">
                                    <Link
                                      to={galleryGxl1Img}
                                      className="main-gallary"
                                    >
                                      <img
                                        src={galleryGxl1Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                  </div>
                                  <div className="single-grid-2 mt-24">
                                    <Link
                                      to={galleryGxl2Img}
                                      className="single-grid-2 main-gallary mt-30"
                                    >
                                      <img
                                        src={galleryGxl2Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                  </div>
                                  <div className="single-grid-2 mt-24">
                                    <Link
                                      to={galleryGxl3Img}
                                      className="main-gallary mt-30"
                                    >
                                      <img
                                        src={galleryGxl3Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="package-d-sidebar">
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="p-sidebar-form">
                        <form>
                          <h5 className="package-d-head">
                            {this.state.lang == "M"
                              ? "पॅकेज बुक करा"
                              : "Book This Package"}
                          </h5>
                          <h5 id="orderErrMsg" className="errorSel"></h5>
                          <div className="row">
                            <div className="col-lg-12">
                              <input
                                maxLength="25"
                                type="text"
                                value={this.state.name}
                                placeholder={
                                  this.state.lang == "M"
                                    ? "तुमचं पुर्ण नाव"
                                    : "Your Full Name"
                                }
                                onChange={(e) =>
                                  this.setState({ name: e.target.value })
                                }
                              />
                            </div>
                            <div className="col-lg-12">
                              <input
                                type="email"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                                placeholder={
                                  this.state.lang == "M"
                                    ? "तुमचा ईमेल आयडी"
                                    : "Your Email Id"
                                }
                              />
                            </div>

                            <ToastContainer />
                            <div className="col-lg-12">
                              {this.state.userinfo.PHONE_NO ? (
                                <label style={{ fontSize: "11px" }}>
                                  {this.state.lang == "M"
                                    ? "लॉगिन फोन नंबर ऑटोफिल्ड"
                                    : "Login Phone Number autofilled"}
                                </label>
                              ) : (
                                <>
                                  <label
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {"* Please Enter WhatsApp Number  "}
                                  </label>
                                  <label style={{ fontSize: "20px" }}> ✆</label>
                                  <label style={{ fontSize: "13px" }}>
                                    {" "}
                                    {"for booking details"}
                                  </label>
                                </>
                              )}

                              <input
                                type="number"
                                disabled={
                                  this.state.userinfo.PHONE_NO ? true : false
                                }
                                placeholder={
                                  this.state.lang == "M"
                                    ? "फोन नंबर "
                                    : "Enter Your Whats App ✆ Phone Number"
                                }
                                value={this.state.phno}
                                onChange={(e) =>
                                  this.setState({ phno: e.target.value })
                                }
                              />
                            </div>
                            {/* <div className="col-lg-12">
                                                        <button style={{marginBottom:"5px"}} className="btn sm-btn btn-outline-primary" type="button">
                                                                                Add Make Up Artistis<i className="bx bx-chevron-right" />
                                                                            </button>
                                                        </div> */}

                            {/* <NextNProgress
                                                            color="#29D"
                                                            startPosition={0.3}
                                                            stopDelayMs={200}
                                                            height={3}
                                                            showOnShallow={true}
                                                        /> */}
                            <div className="col-lg-12">
                              <div
                                className="calendar-input"
                                id="packageCalenderMainDiv"
                              >
                                {this.state.isweekned && (
                                  <label
                                    style={{
                                      fontSize: "14px",
                                      color: "red",
                                      fontStyle: "oblique",
                                    }}
                                  >
                                    {this.state.lang == "M"
                                      ? "त्वरा करा... काही बुकिंग स्लॉट बाकी आहेत !!!"
                                      : "Its Weekend.. packages are booking very fast !!!"}{" "}
                                  </label>
                                )}
                                {this.state.bookingmsg != "" && (
                                  <label
                                    style={{
                                      fontSize: "14px",
                                      color: "blue",
                                      fontStyle: "oblique",
                                    }}
                                  >
                                    {this.state.lang == "M"
                                      ? `त्वरा करा... ${
                                          this.state.bookingmsg > 4
                                            ? 4
                                            : this.state.bookingmsg
                                        }  बुकिंग स्लॉट बाकी आहेत !!!`
                                      : `Hurry up...Only ${
                                          this.state.bookingmsg > 4
                                            ? 4
                                            : this.state.bookingmsg
                                        }  booking slot are left !!!`}{" "}
                                  </label>
                                )}
                                {this.state.bookingmsg == 0 && (
                                  <label
                                    style={{
                                      fontSize: "14px",
                                      color: "blue",
                                      fontStyle: "oblique",
                                    }}
                                  >
                                    {this.state.lang == "M"
                                      ? ` बुकिंग स्लॉट उपलब्ध नाही!!!`
                                      : `Sorry You are late... No booking slot are left. Try for another date !!!`}{" "}
                                  </label>
                                )}
                                <DatePicker
                                  minDate={new Date()}
                                  dateFormat="dd-MM-yyyy"
                                  style={{ margin: "auto !imporatant" }}
                                  selected={startDate}
                                  onChange={(date) =>
                                    this.changeDatepickerHandeller(date)
                                  }
                                  className="input-field check-in"
                                  placeholder="dd-mm-yy"
                                />
                                <i
                                  className="flaticon-calendar"
                                  id="packageCalenderIcon"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              Add extra Person (Till 8 person its free)
                              <select onChange={(e) => this.addadults(e)}>
                                <option value="8">
                                  8 Adults (Included Into package)
                                </option>
                                <option value="9">
                                  9 Adults ({converttocomma(500)})
                                </option>
                                <option value="10">
                                  10 Adults ({converttocomma(1000)})
                                </option>
                                <option value="11">
                                  11 Adults ({converttocomma(1500)})
                                </option>
                                <option value="12">
                                  12 Adults ({converttocomma(2000)})
                                </option>
                                <option value="13">
                                  13 Adults ({converttocomma(2500)})
                                </option>
                              </select>
                            </div>
                            {this.state.pkgid == 3 && (
                              <div className="col-lg-12">
                                Select Package Timing
                                <select onChange={(e) => this.pkgtypefn(e)}>
                                  <option value="1">9 AM to 2 PM</option>
                                  <option value="2">3 PM to 8 PM</option>
                                </select>
                              </div>
                            )}

                            <div
                              style={{ display: "none" }}
                              className="col-lg-12"
                            >
                              <textarea
                                cols={30}
                                rows={7}
                                placeholder={
                                  this.state.lang == "M" ? "मेसिज" : "Message"
                                }
                                defaultValue={""}
                                value={this.state.msg}
                                onChange={(e) =>
                                  this.setState({ msg: e.target.value })
                                }
                              />
                            </div>

                            {/* <div className="col-lg-12 col-md-6">
                                                            <div className="p-sidebar-banner mt-40">
                                                                <img src={sidebarBannar} alt="" className="img-fluid" />
                                                                <div className="sidebar-banner-overlay">
                                                                    <div className="overlay-content">
                                                                        <h3>Add Make Up artist in ₹3,999.00</h3>
                                                                        <p>
                                                                            *Unlimited Looks (Female)

                                                                            *Hair Styling

                                                                            *Styling

                                                                            *Draping

                                                                            *Touch-up after every 2 hours

                                                                        </p>
                                                                        <div onClick={(e) => this.addMakeupArtist()} className="sidebar-banner-btn">
                                                                            <button className="btn btn-outline-primary" type="button"><i className="bx bx-" />
                                                                                Add Make Up Artistis
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}

                            <div
                              className="col-lg-12"
                              style={{ marginTop: "20px" }}
                            >
                              <h5 className="categorie-head">
                                {this.state.lang == "M"
                                  ? "बुकिंग किंमतीची संपूर्ण माहिती"
                                  : "Fare Details:"}
                              </h5>
                            </div>
                            <hr></hr>

                            <div
                              style={{ marginTop: "20px" }}
                              className="col-lg-12"
                            >
                              {this.state.makeupartistprice > 0 && (
                                <>
                                  {/* <p className="categorie-head">{this.state.lang == 'M' ? 'एकूण पॅकेज किंमत:' : 'Total Price:'} <span className="floatright">{converttocomma(parseInt(this.state.pkgdata[0]?.price_cal))}</span></p> */}
                                  <p className="categorie-head">
                                    {this.state.lang == "M"
                                      ? "मेकअप आर्टिस्ट:"
                                      : "Make up Artist:"}
                                    <i
                                      style={{ color: "red", fontSize: "25px" }}
                                      onClick={(e) => this.removeMakeupArtist()}
                                      className="bx bx-x"
                                    ></i>{" "}
                                    <span className="floatright">
                                      {converttocomma(
                                        this.state.makeupartistprice
                                      )}
                                    </span>
                                  </p>
                                </>
                              )}

                              {this.state.adults_amt > 0 && (
                                <>
                                  <p className="categorie-head">
                                    {this.state.lang == "M"
                                      ? "मेकअप आर्टिस्ट:"
                                      : "Extra Person Amount:"}{" "}
                                    <span className="floatright">
                                      {converttocomma(this.state.adults_amt)}
                                    </span>
                                  </p>
                                </>
                              )}

                              <p className="categorie-head">
                                {this.state.lang == "M"
                                  ? "एकूण  किंमत:"
                                  : "Package Price:"}{" "}
                                <span className="floatright">
                                  {converttocomma(
                                    parseInt(this.state.pkgdata[0]?.price_cal) +
                                      parseInt(
                                        !this.state.isdiscount
                                          ? this.state.pkgdata[0]?.DICSOUNT
                                          : 0
                                      )
                                  )}
                                </span>
                              </p>
                              <hr></hr>
                              <p className="categorie-head">
                                {this.state.lang == "M"
                                  ? "बुकिंग रक्कम:"
                                  : "Booking Price:"}{" "}
                                <span className="floatright">
                                  {converttocomma(this.state.minPrice)}
                                </span>
                              </p>
                              {
                              addservicefee(
                                parseInt(this.state.pkgdata[0]?.price_cal) +
                                parseInt(this.state.makeupartistprice) +
                                parseInt(this.state.adults_amt) +
                                parseInt(
                                  !this.state.isdiscount
                                    ? this.state.pkgdata[0]?.DICSOUNT
                                    : 0
                                )) -
                                this.state.minPrice >
                              0 ? (
                                <>
                                  <p className="categorie-head">
                                    {this.state.lang == "M"
                                      ? "उर्वरित रक्कम:"
                                      : "Remainig Amount:"}{" "}
                                    <span className="floatright">
                                      {converttocomma(
                                        addservicefee(
                                          parseInt(this.state.pkgdata[0]?.price_cal) +
                                          parseInt(this.state.makeupartistprice) +
                                          parseInt(this.state.adults_amt) +
                                          parseInt(
                                            !this.state.isdiscount
                                              ? this.state.pkgdata[0]?.DICSOUNT
                                              : 0
                                          )) -
                                          this.state.minPrice
                                      )}
                                    </span>
                                  </p>
                                  <p style={{ fontSize: "12px" }}>
                                    {this.state.lang == "M"
                                      ? "(उर्वरित रक्कम फोटोशूटच्या वेळी भरावी लागेल.)"
                                      : "(Remaining amount shall be paid 2 days before shoot date.)"}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p style={{ fontSize: "12px" }}>
                                    {this.state.lang == "M"
                                      ? "(उर्वरित रक्कम फोटोशूटच्या वेळी भरावी लागेल.)"
                                      : "(You have be paid full amount before shoot date.)"}
                                  </p>
                                </>
                              )}
                            </div>
                            <hr></hr>
                            <div className="col-lg-12">
                              <h7 className="categorie-head">
                                {this.state.lang == "M"
                                  ? "एकूण किंमत"
                                  : "Total Price:"}

                                <span className="floatright">
                                  {converttocomma(
                                    (
                                      parseInt(
                                        this.state.pkgdata[0]?.price_cal
                                      ) +
                                        parseInt(this.state.makeupartistprice) +
                                        parseInt(this.state.adults_amt) +
                                        parseInt(
                                          !this.state.isdiscount
                                            ? this.state.pkgdata[0]?.DICSOUNT
                                            : 0
                                        )
                                    )
                                  )}
                                </span>
                              </h7>
                            <br></br>
                              <h7 className="categorie-head">
                                {this.state.lang == "M"
                                  ? "Service fee"
                                  : "Service Fee:"}

                                <span className="floatright">
                                  {converttocomma(
                                    calServicefee(
                                      parseInt(
                                        this.state.pkgdata[0]?.price_cal
                                      ) +
                                        parseInt(this.state.makeupartistprice) +
                                        parseInt(this.state.adults_amt) +
                                        parseInt(
                                          !this.state.isdiscount
                                            ? this.state.pkgdata[0]?.DICSOUNT
                                            : 0
                                        )
                                    )
                                  )}
                                </span>
                              </h7>
                              
                              <h5 className="categorie-head">
                                {this.state.lang == "M"
                                  ? "एकूण किंमत"
                                  : "Payable Amount:"}

                                <span className="floatright">
                                  {converttocomma(
                                    addservicefee(
                                      parseInt(
                                        this.state.pkgdata[0]?.price_cal
                                      ) +
                                        parseInt(this.state.makeupartistprice) +
                                        parseInt(this.state.adults_amt) +
                                        parseInt(
                                          !this.state.isdiscount
                                            ? this.state.pkgdata[0]?.DICSOUNT
                                            : 0
                                        )
                                    )
                                  )}
                                </span>
                                {/* remove gst */}
                                <p style={{display:"none"}}>
                                  <span>(GST Amount included) </span>
                                </p>
                              </h5>
                              {/* remove gst breakup */}
                              <div style={{display:"none"}}>
                                <span
                                  style={{ fontSize: "12px", color: "blue" }}
                                  onClick={() => {
                                    this.setState({
                                      showbreakup: !this.state.showbreakup,
                                    });
                                  }}
                                >
                                  Show GST breakup
                                </span>
                              </div>
                              {this.state.showbreakup && (
                                <>
                                  <hr></hr>
                                  <h5 className="categorie-head">
                                    Net Price:
                                    <span className="floatright">
                                      {converttocomma(
                                        parseInt(
                                          (addservicefee(parseInt(
                                            this.state.pkgdata[0]?.price_cal
                                          ) +
                                            parseInt(
                                              this.state.makeupartistprice
                                            ) +
                                            parseInt(this.state.adults_amt) +
                                            parseInt(
                                              !this.state.isdiscount
                                                ? this.state.pkgdata[0]
                                                    ?.DICSOUNT
                                                : 0
                                            ))) /
                                            (1 + 18 / 100)
                                        )
                                      )}
                                    </span>
                                  </h5>
                                  <h5 className="categorie-head">
                                    GST Amount Price:
                                    <span className="floatright">
                                      {converttocomma(
                                        parseInt(
                                            
                                            addservicefee( parseInt(
                                            this.state.pkgdata[0]?.price_cal
                                          ) +
                                            parseInt(
                                              this.state.makeupartistprice
                                            ) +
                                            parseInt(this.state.adults_amt) +
                                            parseInt(
                                              !this.state.isdiscount
                                                ? this.state.pkgdata[0]
                                                    ?.DICSOUNT
                                                : 0
                                            ))
                                            
                                            -
                                            
                                            addservicefee((parseInt(
                                              this.state.pkgdata[0]?.price_cal
                                            ) +
                                              parseInt(
                                                this.state.makeupartistprice
                                              ) +
                                              parseInt(this.state.adults_amt) +
                                              parseInt(
                                                !this.state.isdiscount
                                                  ? this.state.pkgdata[0]
                                                      ?.DICSOUNT
                                                  : 0
                                              ))) /
                                              (1 + 18 / 100)
                                        )
                                      )}
                                    </span>
                                  </h5>
                                </>
                              )}
                            </div>
                            <hr></hr>
                            <div className="col-lg-12">
                              <input
                                type="checkbox"
                                id="checkTick"
                                onClick={(e) => this.checkTickBox()}
                              />{" "}
                              {this.state.lang == "M"
                                ? "या बॉक्समध्ये खूण करून, तुम्ही आमची उत्पादने आणि सेवांसाठी सर्व अटी आणि शर्ती असल्याचे मान्य करता."
                                : "By checking this box, you agree to be all terms and condition for our products and services."}
                              <a href={termPdf}>Terms and Conditions</a>
                            </div>

                            <div
                              style={{ marginTop: "10px" }}
                              className="col-lg-12"
                            >
                              {this.state.bookingmsg > 0 && (
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={(e) => this.handlePayNow()}
                                  type="button"
                                >
                                  {/* Pay Now {converttocomma(parseInt(this.state.pkgdata[0]?.price_cal) + parseInt(this.state.makeupartistprice))} <i className="bx bx-log-in-circle" /> */}
                                  {this.state.lang == "M"
                                    ? "बुक करा"
                                    : "Pay Now"}{" "}
                                  {converttocomma(this.state.minPrice)}{" "}
                                  <i className="bx bx-log-in-circle" />
                                </button>
                              )}
                            </div>
                            <span style={{ fontSize: "12px" }}>
                              {this.state.lang == "M" ? "(किमान" : "(Minimum "}{" "}
                              {converttocomma(this.state.minPrice)}{" "}
                              {this.state.lang == "M"
                                ? "बुकिंगसाठी भरावी लागणारी रक्कम.)"
                                : "amount need to pay for booking.) "}
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-cards mt-40">
                                            <h5 className="package-d-head">Popular Packages</h5>
                                            <ul className="package-cards">
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <div className="package-date-sm">
                                                            <strong><i className="flaticon-calendar" />5 Days/6 night</strong>
                                                        </div>
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/package-details`}>Lake Garda</Link>
                                                        </h3>
                                                        <h5><span>$180</span>/ Per Person</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <div className="package-date-sm">
                                                            <strong><i className="flaticon-calendar" />5 Days/6 night</strong>
                                                        </div>
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/package-details`}>Paris Hill Tour</Link>
                                                        </h3>
                                                        <h5><span>$180</span>/ Per Person</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_2} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <div className="package-date-sm">
                                                            <strong><i className="flaticon-calendar" />5 Days/6 night</strong>
                                                        </div>
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/package-details`}>Amalfi Costa</Link>
                                                        </h3>
                                                        <h5><span>$180</span>/ Per Person</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_3} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <div className="package-date-sm">
                                                            <strong><i className="flaticon-calendar" />5 Days/6 night</strong>
                                                        </div>
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/package-details`}>Mount Dtna</Link>
                                                        </h3>
                                                        <h5><span>$180</span>/ Per Person</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <div className="package-date-sm">
                                                            <strong><i className="flaticon-calendar" />5 Days/6 night</strong>
                                                        </div>
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/package-details`}>Fench Rivirany</Link>
                                                        </h3>
                                                        <h5><span>$180</span>/ Per Person</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-organizer mt-40">
                                            <h5 className="package-d-head">Organized By</h5>
                                            <div className="organizer-card">
                                                <div className="organizer-img">
                                                    <img src={organizer} alt="" />
                                                </div>
                                                <div className="organizer-info">
                                                    <h5>Travelhotel</h5>
                                                    <p>Member since 2021</p>
                                                    <ul className="organizer-rating">
                                                        <li><i className="bx bxs-star" /></li>
                                                        <li><i className="bx bxs-star" /></li>
                                                        <li><i className="bx bxs-star" /></li>
                                                        <li><i className="bx bxs-star" /></li>
                                                        <li><i className="bx bx-star" /></li>
                                                    </ul>
                                                    <h5>500 Reviews</h5>
                                                </div>
                                            </div>
                                            <div className="p-ask-btn">
                                                <Link to={`${process.env.PUBLIC_URL}/contact`} >ASK A QUESTION</Link>
                                            </div>
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function converttocomma(str) {
  console.log(str);
  if (str) {
    str=Math.round((str));
    return "₹ " + str.toLocaleString();
  }
  return "₹ " + 0;
}
function addservicefee(amt) {
  return parseInt(amt) + parseInt(amt * 2.5) / 100;
}
function calServicefee(amt) {
  return parseInt(amt * 2.5) / 100;
}
async function displayRazorpay1(
  pkgid,
  price,
  name,
  phno,
  email,
  msg,
  makeup,
  date,
  isTickChecked,
  packageId,
  adults,
  pkgtype
) {
  // alert(pkgid,price)
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  const reqbody = {
    pkg_id: pkgid,
    price: price,
    name: name,
    phno: phno,
    email: email,
    msg: msg,
    makeup: makeup,
    date: date,
    isTickChecked: isTickChecked,
    packageId: packageId,
    adults: adults,
    pkgtype: pkgtype,
  };
  document.querySelector("#orderErrMsg").innerHTML = "";
  const result = await axios.post(`${host_url}/api/v1/getrzp`, reqbody);
  if (!result) {
    alert("Server error. Are you online?");
    return;
  }
  if (result?.data?.status == false) {
    // alert(result?.data?.validation);
    console.log("result", result?.data?.validation);
    document.querySelector("#orderErrMsg").innerHTML = result?.data?.validation;
    return;
  }
  // alert(host_url)
  const { amount, id: order_id, currency, tastaid } = result.data;
  const options = {
    key: result.key, // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: name,
    description: "Snap City In Pune",
    image: "",
    order_id: order_id,

    //
    redirect: true,
    callback_url: host_url + "/api/v1/booking?ordid=" + tastaid,
    prefill: {
      name: name,
      email: email,
      contact: phno,
    },
    notes: {
      success_url: host_url + "/api/v1/booking?ordid=" + tastaid,
      fail_url: host_url + "/api/v1/booking?ordid=" + tastaid,
    },
    theme: {
      color: "#61dafb",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}

export default PackageDetails;
