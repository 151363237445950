import React, { Component } from "react";
import {Link} from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import host_url from "../../../services/environment";


class Destinations extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

      const destinationsOptions = {
          stagePadding: 1,
          items: 1,
          loop: true,
          margin:20,
          smartSpeed: 1500,
          autoplay: true,
          dots: true,
          nav: true,
          navText : ["<i class='bx bx-chevron-left' ></i>","<i class='bx bx-chevron-right'></i>"],
          responsive:{
              0:{
                  items:1,
                  nav:false,
                  dots : false
              },
              600:{
                  items:2,
                  nav:true,
                  dost : true,
              },
              1000:{
                  items:3,
                  nav:true,
                  loop:true
              }
          }
      };

    return (
       <>
           {/* =============== Destinations area start =============== */}
           <div className="destinations-area pt-105">
               <div className="container">

                   <div className="row">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                           <div className="section-head pb-40">
                               {/* <h5>Choose Your Constumes </h5> */}
                               <h2>We have best Constumes For Your Prewedding </h2>
                           </div>
                       </div>
                   </div>

                   <div className="row">
                       <div className="col-lg-3 col-md-3">
                           <div className="package-slider-wrap">
                               <img src={`${host_url}/fetchimage?type=packages&name=main.jpg`} alt="" className="img-fluid" />
                               {/* <div className="pakage-overlay">
                                   <strong> call us - 9130901234</strong>
                               </div> */}
                           </div>
                       </div>
                       <div className="col-lg-9 col-md-9">
                           <OwlCarousel className="row owl-carousel destinations-1"  {...destinationsOptions}>
                               <div className="package-card">
                                   <div className="package-thumb">
                                       <Link>
                                           <img src={`${host_url}/fetchimage?type=packages&name=2.jpg`} alt="" className="img-fluid" />
                                       </Link>
                                   </div>
                               </div>
                            
                            
                               <div className="package-card">
                                   <div className="package-thumb">
                                       <Link>
                                           <img src={`${host_url}/fetchimage?type=packages&name=4.jpg`} alt="" className="img-fluid" />
                                       </Link>
                                   </div>
                               </div>
                            
                              
                            
                               <div className="package-card">
                                   <div className="package-thumb">
                                       <Link>
                                           <img src={`${host_url}/fetchimage?type=packages&name=6.jpg`} alt="" className="img-fluid" />
                                       </Link>
                                   </div>
                               </div>
                            
                               <div className="package-card">
                                   <div className="package-thumb">
                                       <Link>
                                           <img src={`${host_url}/fetchimage?type=packages&name=7.jpg`} alt="" className="img-fluid" />
                                       </Link>
                                   </div>
                               </div>
                            
                               <div className="package-card">
                                   <div className="package-thumb">
                                       <Link>
                                           <img src={`${host_url}/fetchimage?type=packages&name=8.jpg`} alt="" className="img-fluid" />
                                       </Link>
                                   </div>
                               </div>
                            
                               <div className="package-card">
                                   <div className="package-thumb">
                                       <Link>
                                           <img src={`${host_url}/fetchimage?type=packages&name=9.jpg`} alt="" className="img-fluid" />
                                       </Link>
                                   </div>
                               </div>
                            
                               <div className="package-card">
                                   <div className="package-thumb">
                                       <Link>
                                           <img src={`${host_url}/fetchimage?type=packages&name=10.jpg`} alt="" className="img-fluid" />
                                       </Link>
                                   </div>
                               </div>
                            
                             </OwlCarousel>
                       </div>
                   </div>
              </div>
           </div>

           {/* =============== Destinations area end =============== */}
       </>
    );
  }
}

export default Destinations;
