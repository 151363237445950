import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getAllOrder } from "../../../services/homepage"
import cookieCutter from 'cookie-cutter';
import moment from "moment";
import host_url from "../../../services/environment";

class Faq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        let userinfo = cookieCutter.get('userInfo');
        userinfo = userinfo ? JSON.parse(userinfo)?.resp[0] : {};
        if (userinfo) {
            getAllOrder({ phonno: userinfo.PHONE_NO })
                .then(resp => {
                    this.setState({ data: resp });
                });
        }
    }
    render() {
        const redirecttopg = (id) => {
            window.location.href = `${host_url}/api/v1/generatelink?ord_id=${id}`;
        }

        return (
            <div>
                {/* ===============  breadcrumb area start =============== */}
                <div style={{display:'none'}} className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-sm-6">
                                <div className="breadcrumb-wrap">
                                    <h2>My Transactions</h2>
                                    {/* <ul className="breadcrumb-links">
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                                            <i className="bx bx-chevron-right" />
                                        </li>
                                        <li>My Transactions</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ===============  breadcrumb area end =============== */}
                {/* ===============  faq wrapper start =============== */}

                
                <div className="faq-wrapper pt-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10">
                                <div className="faq-wrap">
                                    <div className="accordion-box">
                                        <h5>My Transactions</h5>
                                        <div className="accordion" id="accordionExample">
                                            {this.state?.data?.reverse()?.map((d, index) =>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={d.ORDER_ID}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + d.SNAP_ID} aria-expanded="false" aria-controls={d.SNAP_ID}>
                                                            {d?.PACKAGE_NAME} {" "}<b>{moment(d?.BOOKING_DATE).format("DD-MMM-YYYY")} </b>
                                                            {d?.PAYMENT_STATUE == '2' ? 'Booking Confirmed' : (d?.PAYMENT_STATUE == '4' ? 'Payment Failed' : 'Payment Pending')}
                                                            <i className="bx bx-chevron-down" />

                                                        </button>
                                                    </h2>
                                                    <div id={d.SNAP_ID} className={index==0 ? "accordion-collapse" : "accordion-collapse collapse"} aria-labelledby={d.ORDER_ID} data-bs-parent="#accordionExample2">
                                                        <div className="accordion-body blog-details">
                                                            <div className="blog-info">
                                                                <b>Snap Id</b>SNAP{d?.ORDER_ID}/{d?.SNAP_ID}
                                                                <Link to={`${process.env.PUBLIC_URL}/booking?ordid=${d?.SNAP_ID}`}>View Booking</Link>
                                                                {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>Package Name</b>{d?.PACKAGE_NAME} ({" "} {d?.TYPE} {" "})
                                                    {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-card-xl">
                                                <div style={{ margin: "14px" }} className="blog-title">
                                                   {this.state.lang=='M' ? 'पेमेंट तपशील:' : 'Payment details:' }
                                            </div>


                                                <div className="blog-details">
                                                    <div className="blog-info">
                                                        <b>{this.state.lang=='M' ? 'बुकिंग रक्कम:' : 'Booking Amount:'}</b>{converttocomma(d?.BOOKING_ACTUAL_PRICE)}
                                                        {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                    </div>
                                                    <hr></hr>
                                                    {d?.PAYMENT_STATUE == '2' ?
                                                        <>
                                                            <div className="blog-info">
                                                                <b>{this.state.lang=='M' ? 'देय रक्कम:' : 'Paid Amount:'}</b>{converttocomma(d?.BOOKING_AMOUNT)}
                                                                {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>{this.state.lang=='M' ? 'बुकिंगच्या 1 दिवसापूर्वी देय असलेली उर्वरित रक्कम' : 'Remaining amount due' }</b> {converttocomma(d?.BOOKING_REMAINIG_AMOUNT)}
                                                                {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                        </> : <>
                                                            <div style={{ color: "red" }} className="blog-info">
                                                                <b>{this.state.lang=='M' ? 'पेमेंट अयशस्वी' : 'Payment Failed'}</b>
                                                                {/* <Link to={`#`} className="blog-writer">{this.state?.data?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                        </>}
                                                    <div className="blog-btn">

                                                        {d.PAYMENT_STATUE == '2' && d.BOOKING_REMAINIG_AMOUNT > 0 ?
                                                            <button style={{ margin: "10px" }} className="btn btn-outline-primary" onClick={()=>{redirecttopg(d.SNAP_ID)}} type="button">
                                                                Pay Now {converttocomma(d?.BOOKING_REMAINIG_AMOUNT)} <i className="bx bx-log-in-circle" />
                                                            </button> : <></>
                                                        }
                                                        {/* {this.state?.data?.PAYMENT_STATUE == '2' && <button className="btn btn-outline-primary" onClick={downloadlink} type="button">
                                                            Download Voucher
                                                        <i className="bx bx-log-in-download" />
                                                        </button>
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>

                                                            <div className="blog-info">
                                                                <b>Booking Date</b> {moment(d?.BOOKING_DATE).format("DD-MMM-YYYY")}
                                                                {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>

                                                            <div className="blog-info">
                                                                <b>Location </b>Snap City PM99+2H, Talegaon Dabhade, Maharashtra 410506
                                                    {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>Full Name</b>{d?.FULL_NAME}
                                                                {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>Email Id</b>{d?.EMAIL_ID}
                                                                {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>Phone Number</b>{d?.PHONE_NUMBER}
                                                                {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>
                                                            <div className="blog-info">
                                                                <b>Make up Artist</b>Not available
                                                    {/* <Link to={`#`} className="blog-writer">{d?.PACKAGE_NAME}</Link> */}
                                                            </div>
                                                            <hr></hr>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* ===============  faq wrapper end =============== */}
            </div>
        );
    }
}
function converttocomma(str) {
    console.log(str)
    if (str) {
        return "₹ " + str.toLocaleString();
    } return "₹ " + 0;
}
export default Faq;
