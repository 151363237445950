import React, { Component } from "react";
import { Link } from "react-router-dom";


class Error extends Component {
  render() {
    return (
      <>
        <div className="footer-area" style={{backgroundColor:"#040116",paddingTop:"10px"}}>
          <div className="container">
            <div className="row">
            <div className="col-lg-2 col-md-4">

            </div>
              <div className="col-lg-10 col-md-4">
                <div className="footer-links payment-links">
                  <h5 className="widget-title">CANCELLATION POLICY:</h5>
                  <div className="contact-box">
                    <span><i className="bx bx-circle" /></span>
                    <div>
                      <Link>Cancellations will be considered only if the cancellation request is emailed at snapcityinpune@gmail.com within 6 hours of placing an order.</Link>
                    </div>
                  </div>
                  <div className="contact-box">
                    <span><i className="bx bx-circle" /></span>
                    <div>
                      <Link>Any cancellation on booking, prior to 15 days from shoot date will be non-refundable.</Link>
                    </div>
                  </div>
                  <div className="contact-box">
                    <span><i className="bx bx-circle" /></span>
                    <div>
                      <Link>However, in a case where a transaction has been completed by you on the Site, and money has been charged to your card or bank account but a booking confirmation email is not delivered within 24 hours of your completion of the transaction then you may inform us by sending us an email on snapcityinpune@gmail.com  In such a scenario, we shall verify the transaction and send you the confirmation within one working day.</Link>
                    </div>
                  </div>
                  <div className="contact-box">
                    <span><i className="bx bx-circle" /></span>
                    <div>
                      <Link>In Case of cancellation before one month of schedule date, the guest shall be refunded within one month from date of cancellation. The booking amount after deducting therefrom such expenses as incurred by the management for the booking event. Government charges or payment gateway charges (e-Payment charges) are not refundable on cancellation of bookings.</Link>
                    </div>
                  </div>
                  <div className="contact-box">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Error;
