import axios from "axios";
// import { logoutUser, updateToken } from "../redux/actions/auth";
// import reduxStore from "../redux/stores";

const AxiosBase = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

// AxiosBase.interceptors.request.use(
//   (req) => {
//     if (!req?.headers?.rerequest) {
//       const root = JSON.parse(localStorage.getItem("persist:root"));
//       const authData = root?.auth ? JSON.parse(root?.auth) : "";
//       if (authData?.accessToken) {
//         req.headers = {
//           Authorization: `Bearer ${authData?.accessToken}`,
//         };
//       }
//     }
//     return req;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// let errorUrl; // using this to avoid any loop
// // response call intercept
// AxiosBase.interceptors.response.use(
//   (res) => {
//     if (res.config.url === `/admin/login`) {
//       AxiosBase.defaults.headers = {
//         Authorization: `Bearer ${res.data.accessToken}`,
//       };
//       /* -------------- Temporary Code start here -----------------------*/
//       axios.defaults.headers = {
//         Authorization: `Bearer ${res.data.accessToken}`,
//       };
//       /* -------------- Temporary Code end here -----------------------*/
//     }
//     if (res.config.url === errorUrl) {
//       errorUrl = "";
//     }
//     return res;
//   },
//   async (error) => {
//     if (
//       error?.response?.status === 401 &&
//       error?.response.config.url !== errorUrl &&
//       error?.response.config.url !== `/admin/refresh-login` &&
//       error?.response.config.url !== `/admin/login`
//     ) {
//       errorUrl = error?.response.config.url;
//       const { store } = reduxStore();
//       try {
//         const root = JSON.parse(localStorage.getItem("persist:root"));
//         const authData = root?.auth ? JSON.parse(root?.auth) : "";
//         if (authData?.refreshToken) {
//           const res = await AxiosBase.post(`/admin/refresh-login`, {
//             token: authData?.refreshToken,
//           });
//           store.dispatch(
//             updateToken({
//               accessToken: res.data.accessToken,
//               refreshToken: res.data.refreshToken,
//             })
//           );
//           AxiosBase.defaults.headers = {
//             Authorization: `Bearer ${res.data.accessToken}`,
//           };
//           /* -------------- Temporary Code start here -----------------------*/
//           axios.defaults.headers = {
//             Authorization: `Bearer ${res.data.accessToken}`,
//           };
//           /* -------------- Temporary Code end here -----------------------*/
//           error.response.config.headers = {
//             Authorization: `Bearer ${res.data.accessToken}`,
//             rerequest: true,
//           };

//           if (error?.response?.config?.data) {
//             error.response.config.data = JSON.parse(error.response.config.data);
//           }
//           return AxiosBase.request(error.response.config);
//         }
//         store.dispatch(logoutUser("/login", "Timeout"));
//         // localStorage.clear();
//       } catch (err) {
//         store.dispatch(logoutUser("/login", "Timeout"));
//         // localStorage.clear();
//       }
//     }else{
//       return Promise.reject(error);
//     }

//     return Promise.reject(error);
//   }
// );

// /* -------------- Temporary Code start here -----------------------*/
// /* ------------- -----------------------*/
// axios.interceptors.request.use(
//   (req) => {
//     if (!req?.headers?.rerequest) {
//       const root = JSON.parse(localStorage.getItem("persist:root"));
//       const authData = root?.auth ? JSON.parse(root?.auth) : "";
//       if (authData?.accessToken) {
//         req.headers = {
//           Authorization: `Bearer ${authData?.accessToken}`,
//         };
//       }
//     }
//     return req;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// let errorUrlv2; // using this to avoid any loop
// // response call intercept
// axios.interceptors.response.use(
//   (res) => {
//     if (res.config.url === `${process.env.REACT_APP_API_URL}/api/admin/login`) {
//       /* axiosbase temp code start here*/
//       AxiosBase.defaults.headers = {
//         Authorization: `Bearer ${res.data.accessToken}`,
//       };
//       /* axiosbase temp code end here*/
//       axios.defaults.headers = {
//         Authorization: `Bearer ${res.data.accessToken}`,
//       };
//     }
//     if (res.config.url === errorUrlv2) {
//       errorUrlv2 = "";
//     }
//     return res;
//   },
//   async (error) => {
//     if (
//       error?.response?.status === 401 &&
//       error?.response.config.url !== errorUrlv2 &&
//       error?.response.config.url !==
//         `${process.env.REACT_APP_API_URL}/api/admin/refresh-login` &&
//       error?.response.config.url !==
//         `${process.env.REACT_APP_API_URL}/api/admin/login`
//     ) {
//       errorUrlv2 = error?.response.config.url;
//       const { store } = reduxStore();
//       try {
//         const root = JSON.parse(localStorage.getItem("persist:root"));
//         const authData = root?.auth ? JSON.parse(root?.auth) : "";
//         if (authData?.refreshToken) {
//           const res = await axios.post(
//             `${process.env.REACT_APP_API_URL}/api/admin/refresh-login`,
//             {
//               token: authData?.refreshToken,
//             }
//           );
//           store.dispatch(
//             updateToken({
//               accessToken: res.data.accessToken,
//               refreshToken: res.data.refreshToken,
//             })
//           );
//           /* axiosbase temp code start here*/
//           AxiosBase.defaults.headers = {
//             Authorization: `Bearer ${res.data.accessToken}`,
//           };
//           /* axiosbase temp code end here*/
//           axios.defaults.headers = {
//             Authorization: `Bearer ${res.data.accessToken}`,
//           };
//           error.response.config.headers = {
//             Authorization: `Bearer ${res.data.accessToken}`,
//             rerequest: true,
//           };

//           if (error?.response?.config?.data) {
//             error.response.config.data = JSON.parse(error.response.config.data);
//           }
//           return axios.request(error.response.config);
//         }
//         store.dispatch(logoutUser("/login", "Timeout"));
//         // localStorage.clear();
//       } catch (err) {
//         store.dispatch(logoutUser("/login", "Timeout"));
//         // localStorage.clear();
//       }
//     }

//     return Promise.reject(error);
//   }
// );
// /* ------------- -----------------------*/
// /* -------------- Temporary Code end here -----------------------*/

export default AxiosBase;

// export const handleError = (error) => {
//   alert("handle Error");
//   console.log("HandleError : ", JSON.stringify(error));
//   if (error.response.status === 404) {
//   }
// };
