import { Component } from "react";
import MainBanner from "./MainBanner";
import MiddleBanner from "./MiddleBanner";
import Packages from "./Packages";
import Blog from "./Blog";
import Destinations from "./Destinations";
import { getHomepage } from "../../../services/homepage";
import cookieCutter from "cookie-cutter";
import ReactModal from "react-modal";
import host_url from "../../../services/environment";

class HomePage extends Component {
  constructor(props) {
    super(props);
    let lang = cookieCutter.get("lang");

    const queryParams = new URLSearchParams(window.location.search);
        let clearPopup = queryParams.get("clear");
        if (clearPopup==='true') {
            cookieCutter.set('clearPopup', true);
        }
 
    this.state = {
      data: [],
      lang: lang,
      showPopup: false,
      isMobile: window.innerWidth <= 768
    };

  }

  componentDidMount() {
    getHomepage(this.state.lang).then((resp) => {
      this.setState({ data: resp });
    });
    // this.timer = setTimeout(() => {
      
    //   this.setState({ showPopup: (cookieCutter.get('clearPopup') === 'false') ? false : true });
    // }, 11000);

    // window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    clearTimeout(this.timer); // Cleanup the timer on component unmount
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  closePopup = () => {
    this.setState({ showPopup: false });
  }

  submitForm = () => {
    // Add your form submission logic here
    this.setState({ showPopup: false });
    cookieCutter.set('clearPopup', 'false');
  }

  render() {
    const opts = {
      height: "590",
      width: "1240",
      playerVars: {
        autoplay: 1,
      },
    };

    const customStyles = this.state.isMobile
      ? {
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },
          content: {
            position: "relative",
            top: "5px",
            left: "5px",
            right: "5px",
            bottom: "5px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "30px",
            outline: "none",
            padding: "5px",
            boxShadow: "0 24px 80px rgba(0, 0, 0, 0.1)",
            maxWidth: "100%",
            maxHeight: "80%"
          },
        }
      : {
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },
          content: {
            position: "relative",
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "0%",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "10px",
            outline: "none",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "800px",
            maxHeight: "80%"
          },
        };

    return (
      <div>
        {/* Start Preloader Area */}
        <div className="preloader">
          <div className="loader loader1">
            <span style={{ "--i": 1 }} />
            <span style={{ "--i": 2 }} />
            <span style={{ "--i": 3 }} />
            <span style={{ "--i": 4 }} />
            <span style={{ "--i": 5 }} />
            <span style={{ "--i": 6 }} />
            <span style={{ "--i": 7 }} />
            <span style={{ "--i": 8 }} />
            <span style={{ "--i": 9 }} />
            <span style={{ "--i": 10 }} />
            <span style={{ "--i": 11 }} />
            <span style={{ "--i": 12 }} />
            <span style={{ "--i": 13 }} />
            <span style={{ "--i": 14 }} />
            <span style={{ "--i": 15 }} />
            <span style={{ "--i": 16 }} />
            <span style={{ "--i": 17 }} />
            <span style={{ "--i": 18 }} />
            <span style={{ "--i": 19 }} />
            <span style={{ "--i": 20 }} />
            <div className="rocket" />
          </div>
        </div>
        {/* End Preloader Area */}

        {!this.state.showPopup ? (
          <>
            <MainBanner
              lang={this.state.lang}
              bannerdata1={this.state.data?.homeBanner}
            />
            <Packages
              lang={this.state.lang}
              packageDetails={this.state.data?.packageDetails}
            />
            <MiddleBanner
              lang={this.state.lang}
              bannerdata1={this.state.data?.homeBanner}
            />
            <Destinations />
            <div className="section-head pb-30">
              <h2>Take a Snapcity's Tour</h2>
            </div>
            <video
              className="vidocss"
              controls
              muted
              autoPlay
              preLoad="auto"
              loop
            >
              {/* <source src={snapcity} /> */}
            </video>
            <Blog lang={this.state.lang} sets={this.state.data.sets} />
          </>
        ) : (
          <ReactModal
            isOpen={true}
            onRequestClose={this.closePopup}
            ariaHideApp={false}
            contentLabel="Enquiry Form"
            closeTimeoutMS={200}
            style={customStyles}
          >
            <div>
              
              <div className="inner">
                <div className="image-holder">
                  <img
                    src={`${host_url}/fetchimage?type=packages&name=main.jpg`}
                    alt=""
                    className="img-fluid height20"
                  />
                </div>
                <div>
                  <h3>Enquiry Form</h3>

                  <div className="form-wrapper">
                    <input
                      type="text"
                      maxLength="20"
                      placeholder="First Name"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-wrapper">
                    <input
                      type="text"
                      maxLength="20"
                      placeholder="Last Name"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-wrapper">
                    <input
                      type="number"
                      maxLength="12"
                      placeholder="Mobile Number"
                      className="form-control"
                    ></input>
                  </div>
                  <button className="btn btn-outline-primary" type="button" onClick={this.submitForm}>Submit <i className="bx bx-right-arrow-circle"></i></button>
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  <button className="btn btn-outline-primary" type="button" onClick={this.closePopup}>Cancel <i className="bx bx-window-close"></i></button>
                </div>
              </div>
            </div>
          </ReactModal>
        )}
      </div>
    );
  }
}

export default HomePage;
