import AxiosBase from "./axiosBase";
import host_url from "./environment";


export const getOrder = (id,lang) => {
  return new Promise((resolve, reject) => {
    console.log("Coming");
    AxiosBase.get(`${host_url}/api/v1/getOrder?orderid=${id}&lang=${lang}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const getAllOrder = (req) => {
  return new Promise((resolve, reject) => {
    console.log("Coming");
    AxiosBase.get(`${host_url}/api/v1/getCutomerOrderData?phonno=${req.phonno}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const getHomepage = (lang) => {
  return new Promise((resolve, reject) => {
    console.log("Coming");
    AxiosBase.get(`${host_url}/api/v1/getHomePageData?lang=${lang}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const signin = (req) => {
  return new Promise((resolve, reject) => {
    console.log("Coming");
    AxiosBase.post(`${host_url}/api/v1/signin`,req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}
export const forgot = (req) => {
  return new Promise((resolve, reject) => {
    console.log("Coming");
    AxiosBase.post(`${host_url}/api/v1/forgotpassword`,req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const signup = (req) => {
  return new Promise((resolve, reject) => {
    console.log("Coming");
    AxiosBase.post(`${host_url}/api/v1/signup`,req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}


export const sendotp = (req) => {
  return new Promise((resolve, reject) => {
    console.log("Coming");
    AxiosBase.get(`${host_url}/api/v1/sendotp?mobile=${req.phoneno}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const getPackages = (req) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`${host_url}/api/v1/getPackagedtl?pkgid=${req.id}&lang=${req.lang}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}


export const getOrdersCount = (req) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`${host_url}/api/v1/getbookingcount?date=${req.date}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const getTemplates = (req) => {
  return new Promise((resolve, reject) => {
    let reqbody={
      "userId":req.id
    }
    AxiosBase.post(`${host_url}/api/v1/getTemplate`,reqbody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}


