import React, { Component } from "react";
import { Link } from "react-router-dom";
import host_url from "../../../services/environment";
class Packages extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <>
        {/* ===============  Package  area start =============== */}
        {this.props.packageDetails &&
          <div className="package-area pt-120">
            <div className="container">

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="section-head pb-45">
                    <h5>{this.props.lang == 'M' ? "तुमचे पॅकेज निवडा" : "Choose Your Package"}</h5>
                    <h2>{this.props.lang == 'M' ? "तुमच्या शूटसाठी तुमचे सर्वोत्तम पॅकेज निवडा" : "Select Your best Package For Your Shoot"}</h2>
                  </div>
                </div>
              </div>

              <div className="row">


                {this.props?.packageDetails?.map((d, index) => {
                  return (
                    <div className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                      <div className="package-card">
                        <div className="package-thumb">
                          <Link to={`${process.env.PUBLIC_URL}/package-details?pkg=${d[Object.keys(d)[0]][0]["PKG_ID"]}`}>
                            <img src={host_url+"/fetchimage?type=packages&name=" + d[Object.keys(d)[0]][0]["IMG_SRC"]} alt="" className="img-fluid" />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["T_PRICE"]}</span>/Per Shoot</h5>
                            <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5>
                          </div>
                          <h3>
                            <i className="flaticon-arrival" />
                            <Link to={`${process.env.PUBLIC_URL}/package-details?pkg=${d[Object.keys(d)[0]][0]["PKG_ID"]}`}>{Object.keys(d)[0]}</Link>
                          </h3>
                          {d[Object.keys(d)[0]].map((d1, index) =>
                            <div className="package-rating"><strong><i className="bx bxs-chevrons-right" /><span> {d1.DETAIL}</span></strong></div>
                          )}

                        </div>
                        <div className="banner-btn">
                          <Link to={`${process.env.PUBLIC_URL}/package-details?pkg=${d[Object.keys(d)[0]][0]["PKG_ID"]}`} className="btn-common-sm">{this.props.lang == 'M' ? "बुक करा" : "Book Now"}</Link>
                        </div>
                      </div>
                    </div>

                  )
                })}

              </div>
            </div>
          </div>
        }
        {/* ===============  Package  area end =============== */}
      </>
    );
  }
}

export default Packages;
