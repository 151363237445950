import React, { Component } from "react";
import { Link } from "react-router-dom";


class Error extends Component {
  render() {
    return (
      <>
        <div className="footer-area" style={{ backgroundColor: "#040116" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-4">
              </div>
              <div className="col-lg-10 col-md-4">
                <div className="footer-links payment-links">
                  <h5 className="widget-title">
                    Website Privacy policies
                  </h5>
                  <div className="contact-box">
                    <div style={{ color: "white" }}>
                      •The Website Owner, including subsidiaries and affiliates snapcityinpune@gmail.com provides the information contained on the website or any of the pages comprising the website www.snapcityinpune.com to visitors (.visitors.) (cumulatively referred to as .you. or .your. hereinafter) subject to the terms and conditions city out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.
                      Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Snapcity in Pune with you in relation to this website.
                      The term Snapcity in Pune refers to the owner of the website whose registered office is Snapcity in Pune Talegaon Dabhade old Mumbai-Pune higway behind Indian oil Petrol Pump 410506.The term 'you' refers to the user or viewer of our website.
                    </div>
                  </div>
                  <br></br>
                  <hr></hr>

                  <h5 className="widget-title">
                    The use of this website is subject to the following terms of use
                  </h5>
                  <div className="contact-box">

                    <div style={{ color: "white" }}>
                      •	The content of the pages of this website is for your general information and use only. It is subject to change without notice.
                      <br></br> •	Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                      <br></br> •	Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
                      <br></br> •	This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                      <br></br> •	All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.
                      <br></br>•	Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.
                      <br></br> •	From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                      <br></br> •	You may not create a link to this website from another website or document without “Snapcity in Pune” prior written consent.
                      <br></br> •	Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.
                      <br></br>  •	"We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time"

                    </div>
                  </div>
                  <br></br>
                  <hr></hr>

                  <h5 className="widget-title">
                    Privacy Policy
                  </h5>
                  <div className="contact-box">

                    <div style={{ color: "white" }}>
                      •	A privacy policy states how you will respect the privacy of your website users. It says what information you will gather, how you will use it and how you will keep it secure.
        <br></br>
                  	•	A privacy policy is a legal document that discloses some or all of the ways a party gathers, uses, discloses and manages a customer's data. The exact contents of a privacy policy will depend upon the applicable law and may need to address the requirements of multiple countries or jurisdictions. While there is no universal guidance for the content of specific privacy policies, a number of organizations provide example forms.

                    </div>
                  </div>

                  <br></br>
                  <hr></hr>

                  <h5 className="widget-title">
                    Website privacy
</h5>
                  <div className="contact-box">

                    <div style={{ color: "white" }}>
                      <br></br>  •	This privacy policy sets out how Snapcity uses and protects any information that you give Snap city when you use this website.
                  	<br></br>•	Snapcity is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
	                  <br></br> •	Snapcity may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 1st January 2022.


  </div>
                  </div>

                  <br></br>
                  <hr></hr>

                  <h5 className="widget-title">
                  What we collect

</h5>
                  <h5 className="widget-title">
                    We may collect the following information

</h5>

                  <div className="contact-box">

                    <div style={{ color: "white" }}>
                      •	Name
                    <br></br>	•	Contact information including email address and Whatsapp Number.
                    <br></br>•	Other information relevant to customer surveys and/or offers.

  </div>
                  </div>

<hr></hr>

                  <h5 className="widget-title">
                    What we do with the information we gather

</h5>
                  <h5 className="widget-title">
                    We require this information to understand your needs and provide you with a better service, and in particular for the following reasons

</h5>
                  <div className="contact-box">

                    <div style={{ color: "white" }}>
                      •	Internal record keeping.
                    <br></br>
	•	We may use the information to improve our products and services.
  <br></br>
	•	We may periodically send promotional emails/Whatsapp Messages about new products, special offers or other information which we think you may find interesting using the email address which you have provided.
  <br></br>
	•	From time to time, we may also use your information to contact you for market research purposes. We may contact you by email or Whatsapp Messages l. We may use the information to customize the website according to your interests.



  </div>
                  </div>


                  <br></br>
                  <hr></hr>
                  <h5 className="widget-title">
                    Security

</h5>
                  <div className="contact-box">

                    <div style={{ color: "white" }}>
                      •	We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
  </div>
                  </div>





                  <br></br>
                  <hr></hr>
                  <h5 className="widget-title">
                    Privacy Policy
</h5>
                  <div className="contact-box">

                    <div style={{ color: "white" }}>
                      •	A privacy policy states how you will respect the privacy of your website users. It says what information you will gather, how you will use it and how you will keep it secure.
<br></br>
•	A privacy policy is a legal document that discloses some or all of the ways a party gathers, uses, discloses and manages a customer's data. The exact contents of a privacy policy will depend upon the applicable law and may need to address the requirements of multiple countries or jurisdictions. While there is no universal guidance for the content of specific privacy policies, a number of organizations provide example forms.

</div>
                  </div>



                  <div className="contact-box">

                    <div>
                      <Link>In Case of cancellation before one month of schedule date, the guest shall be refunded within one month from date of cancellation. The booking amount after deducting therefrom such expenses as incurred by the management for the booking event. Government charges or payment gateway charges (e-Payment charges) are not refundable on cancellation of bookings.</Link>
                    </div>
                  </div>
                  <div className="contact-box">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Error;
