import React, { Component } from "react";
import { Link } from "react-router-dom";


class Error extends Component {
  render() {
    return (
      <>
        <div className="footer-area" style={{backgroundColor:"#040116",paddingTop:"10px"}}>
          <div  className="container">
            <div className="row" >
            <div className="col-lg-2 col-md-2">
              </div>
              <div className="col-lg-10 col-md-4">
                <div className="footer-links payment-links">
                  <h5 className="widget-title">REFUND POLICY:</h5>
                  <div className="contact-box">
                    <span><i className="bx bx-circle" /></span>
                    <div>
                      <Link>The booking amount paid at the time of booking is non-refundable. In case, after booking you decide not to avail the services, you can email us at snapcityinpune@gmail.com and the balance amount will be waved off.</Link>
                    </div>
                  </div>
                  <div className="contact-box">
                    <span><i className="bx bx-circle" /></span>
                    <div>
                      <Link>You cannot reschedule the shoot date after the confirmation of booking.</Link>
                    </div>
                  </div>
                  <div className="contact-box">
                    <span><i className="bx bx-circle" /></span>
                    <div>
                      <Link>In case, if the studio is closed on the day of the shoot due to some unavoidable situations, we can reschedule your shoot to a mutually convenient date.</Link>
                    </div>
                  </div>
                  <div className="contact-box">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Error;
