import React, { Component } from "react";
import { Link } from "react-router-dom";
import { signin, signup, sendotp,forgot } from "../../../services/homepage"
import cookieCutter from 'cookie-cutter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class AboutUs extends Component {
    componentDidMount() {
        this.scrollTop();
    }

    scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    constructor(props) {
        super(props);
        this.state = {
            type: "signin",
            customer: {
                name: "",
                phone: "",
                email: "",
                address: "",
                hyk: "",
                password:"",
                otp:"",
                securityquestion:""
            },
            login: {
                phone: "",
                password: "",
                securityquestion:"",
                cnfpassword:""
            }

        };
    }

    render() {
        const queryParams = new URLSearchParams(window.location.search);
        this.state.type = queryParams.get("type");
        const handlesignin = () => {
            signin(this.state.login)
                .then(resp => {
                    if (resp?.resp?.length > 0) {
                        cookieCutter.set('userInfo', JSON.stringify(resp), { expires: new Date() + 1 })
                        window.location.href = "/"
                    } else {
                        toast("Wrong credentials");
                    }
                });
        }
        const handleforget = () => {
            forgot(this.state.login)
                .then(resp => {
                    if (resp?.resp?.status) {
                        toast("Password Changed");
                    } else {
                        if (resp?.resp?.msg) {
                        toast(resp?.resp?.msg);
                        }else{
                        toast("Wrong credentials");
                        }
                    }
                });
        }
        const handlesignup = () => {
            signup(this.state.customer)
                .then(resp => {
                    if(resp.validation){
                        toast(resp.validation);
                    }
                    else if(resp.status){
                        toast("Signup Successfully.");
                        window.location.href="/"
                    }else{
                        toast("Please input correct information or mobile number already exist.");
                    }
                    
                });
        }
        const handlesendotp = () => {
            if(this.state?.login?.phone){
                sendotp({phoneno:this.state?.login?.phone})
                .then(resp => {
                    toast(`Otp sent on ${this.state?.login?.phone}`);

                });
            }
            else if (this.state?.customer?.phone) {
                sendotp({phoneno:this.state?.customer?.phone})
                    .then(resp => {
                        toast(`Otp sent on ${this.state?.customer?.phone}`);

                    });
            } else {
                toast("Please enter Valid Moible No.");
            }
        }
        return (

            <>
                {/* ===============  breadcrumb area start =============== */}
                {/* <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="breadcrumb-wrap">
                                    <h2>Join With Snap City</h2>
                                    <ul className="breadcrumb-links">
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                                            <i className="bx bx-chevron-right" />
                                        </li>
                                        <li></li>
                                        <ToastContainer />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* ===============  breadcrumb area end =============== */}
                <div className="contact-wrapper pt-90">

                    <div className="container">
                        <div className="contact-inputs pt-100">
                            <div className="row">
                            <div className="col-lg-6">
                                    <div className="contact-form">
                                        {this.state.type == "signup" ?
                                            <>
                                                <form>
                                                    <h5 className="contact-d-head">Sign Up </h5>
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <label>Full Name:</label>
                                                            <input value={this.state.customer.name} onChange={(e) => this.setState({ customer: { ...this.state.customer, name: e.target.value } })} type="text" placeholder="Full Name" required />
                                                        </div>

                                                        <div className="col-lg-6">
                                                        <label>Email Id:</label>
                                                            <input value={this.state.customer.email} onChange={(e) => this.setState({ customer: { ...this.state.customer, email: e.target.value } })} type="email" placeholder="Your Email" required />
                                                        </div>
                                                        <div className="col-lg-6">
                                                        <label>Phone Number:</label>
                                                            <input value={this.state.customer.phone} onChange={(e) => this.setState({ customer: { ...this.state.customer, phone: e.target.value } })} type="text" placeholder="Phone" required />
                                                            <span>Please click here to send otp <span style={{ color: "blue", fontSize: "12px", cursor: "pointer" }} onClick={handlesendotp}>click here</span></span>
                                                        </div>
                                                        <div className="col-lg-6">
                                                        <label>Address:</label>
                                                            <input value={this.state.customer.address} onChange={(e) => this.setState({ customer: { ...this.state.customer, address: e.target.value } })} type="text" placeholder="Address" required />
                                                        </div>
                                                        <div className="col-lg-6">
                                                        <label>OTP:</label>
                                                        <br></br>
                                                            <input type="password" value={this.state.customer.otp} onChange={(e) => this.setState({ customer: { ...this.state.customer, otp: e.target.value } })} placeholder="OTP" required />
                                                        </div>
                                                      
                                                        <div className="col-lg-6">
                                                        <label>Password:</label>
                                                        <br></br>
                                                            <input type="password" value={this.state.customer.password} onChange={(e) => this.setState({ customer: { ...this.state.customer, password: e.target.value } })}  placeholder="Password" required />
                                                        </div>
                                                        <div className="col-lg-8">
                                                        <label>Security Question:</label>
                                                        <label>What is the name of your favorite pet?</label>
                                                            <input type="text" value={this.state.customer.securityquestion} onChange={(e) => this.setState({ customer: { ...this.state.customer, securityquestion: e.target.value } })}  placeholder="name of your favorite pet?" required />
                                                        </div>
                                                       
                                                        
                                                        <div className="col-lg-12">
                                                        <label>How you know:</label>
                                                            <textarea value={this.state.customer.hyk} onChange={(e) => this.setState({ customer: { ...this.state.customer, hyk: e.target.value } })} cols={30} rows={7} placeholder="How you know about Snap City" defaultValue={""} />
                                                        </div>
                                                        <button className="btn btn-warning" onClick={handlesignup} type="button">
                                                            Sign Up<i className="bx bx-log-in-circle" />
                                                        </button>
                                                        <p>(For signup/login query please call to +91 9130901234)</p>
                                                    <br></br>
                                                    </div>
                                                </form>
                                            </>
                                            : 
                                            this.state.type == "signin"  ?
                                            
                                            <>
                                                <form>
                                                    <h5 className="contact-d-head">Please Enter Mobile Number Entered while booking</h5>
                                                    <h7 className="contact-d-head">(SIGN IN)</h7>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <input maxlength={10} value={this.state.login.phone} onChange={(e) => this.setState({ login: { ...this.state.login, phone: e.target.value } })} type="number" placeholder="Enter Your Whats App ✆ Phone Number" required />
                                                            <span>Please click here to send otp <span style={{ color: "blue", fontSize: "12px", cursor: "pointer" }} onClick={handlesendotp}>click here</span></span>
                                                            <p>(Please check OTP on Whats App)</p>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <input value={this.state.login.password} onChange={(e) => this.setState({ login: { ...this.state.login, password: e.target.value } })} type="password" placeholder="OTP" required />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <button className="btn btn-outline-primary" onClick={handlesignin} type="button">
                                                                NEXT<i className="bx bx-log-in-circle" />
                                                            </button>
                                                            <br></br>
                                                            {/* <Link  style={{ marginTop: "10px" }} to={`${process.env.PUBLIC_URL}/contact?type=signup`} className="btn-common-sm">SignUp</Link>
                                                            <Link  style={{ marginTop: "10px",marginLeft: "10px" }} to={`${process.env.PUBLIC_URL}/contact?type=forgotpass`} className="btn-common-sm">Forgot Password</Link> */}
                                                            <p>(For login query please call to +91 9130901234)</p>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                            :<>
                                              <form>
                                                    <h5 className="contact-d-head">Forgot Password</h5>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                        <label>Mobile Number</label>
                                                            <input value={this.state.login.phone} onChange={(e) => this.setState({ login: { ...this.state.login, phone: e.target.value } })} type="number" placeholder="Mobil Number" required />
                                                        </div>
                                                        <div className="col-lg-12">
                                                        <label>Secutity question</label>
                                                        <br></br>
                                                        <label>What is the name of your favorite pet?</label>
                                                        <br></br>
                                                            <input value={this.state.login.securityquestion} onChange={(e) => this.setState({ login: { ...this.state.login, securityquestion: e.target.value } })} type="text" placeholder="name of your favorite pet?" required />
                                                        </div>
                                                        <div className="col-lg-12">
                                                        <label>Password</label>
                                                        <br></br>
                                                            <input value={this.state.login.password} onChange={(e) => this.setState({ login: { ...this.state.login, password: e.target.value } })} type="password" placeholder="Password" required />
                                                        </div>
                                                        <div className="col-lg-12">
                                                        <label>Confirm Password</label>
                                                        <br></br>
                                                            <input value={this.state.login.cnfpassword} onChange={(e) => this.setState({ login: { ...this.state.login, cnfpassword: e.target.value } })} type="password" placeholder="Password" required />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <button className="btn btn-outline-primary" onClick={handleforget} type="button">
                                                                Reset Password<i className="bx bx-log-in-circle" />
                                                            </button>

                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="contact-details">
                                        <h5 className="contact-d-head">Get In Touch</h5>
                                        <p>Spending love moments with your partner at different locations is dream of every couple, for making your dreams come true We have made.
                                        "SNAP CITY"
                                        Where you get 25+ ready sets for your pre-wedding ,Modeling, Maternity, Baby Shoot and all kinds of shoot. We provide all different types of sets according to your requirements
                                        which includes Indoor, Outdoor, Day or Night Shoot themes and sets. The location from all over world can be seen on our sets like London, Italy ,Paris , Greece Venice and many more.
                                    </p>
                                        <ul className="office-clock">
                                            <li>
                                                <div className="clock-icon"><i className="flaticon-clock-1" /></div>
                                                <div className="clock-info">
                                                    <h5>Open Hour</h5>
                                                    <p>Monday to Sunday
                                                    <br /> 9 Am to 8 Pm</p>
                                                </div>
                                            </li>
                                            {/* <li>
                                                <div className="clock-icon"><i className="flaticon-clock-1" /></div>
                                                <div className="clock-info">
                                                    <h5>Close Hour</h5>
                                                    <p>Friday Office Close</p>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="contact-cards">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="contact-card">
                                        <div className="contact-icon"><i className="flaticon-arrival" />
                                        </div>
                                        <div className="contact-info">
                                            <h5>Address</h5>
                                            <p>Snapcity old Puna highway,behind thanda Mamla and Indian Oil Petrol pump Talegaon Dabhade:-410506
</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="contact-card">
                                        <div className="contact-icon"><i className="flaticon-customer-service" />
                                        </div>
                                        <div className="contact-info">
                                            <h5>Email &amp; Phone</h5>
                                            <p>+91 9130901234
                                            snapcityinpune@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="contact-card">
                                        <div className="contact-icon"><i className="flaticon-thumbs-up" />
                                        </div>
                                        <div className="contact-info">
                                            <h5>Social Media</h5>
                                            <ul className="contact-icons">
                                                <li>
                                                    <a href={"https://instagram.com/snapcityinpune?igshid=YmMyMTA2M2Y="}><i className="bx bxl-instagram" /></a>
                                                </li>
                                                <li>
                                                    <a href={"https://www.facebook.com/Snapcity-IN-PUNE-104475251191834"}><i className="bx bxl-facebook" /></a>
                                                </li>
                                                <li>
                                                    <a href={"https://wa.me/message/XCXQSXICIZ2QG1"}><i className="bx bxl-whatsapp" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AboutUs;
