import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

//Import Image
import logoMain from "../../assets/images/logo.jpg"
import secondLogo from "../../assets/images/logo-2.png"
import cookieCutter from 'cookie-cutter';
class Headers extends Component {
    scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    constructor(props) {
        super(props);
        let userinfo = cookieCutter.get('userInfo');
        let lang = cookieCutter.get('lang');
        let signin = false;
        if (!lang) {
            lang = 'E';
        }
        if (userinfo) {
            signin = true;
        }
        this.state = {
            signin: signin,
            userinfo: userinfo ? JSON.parse(userinfo)?.resp[0] : {},
            lang: lang
        };

    }

    render() {
        const queryParams = new URLSearchParams(window.location.search);
        let lang = queryParams.get("lang");
        if (lang) {
            cookieCutter.set('lang', lang);
            window.location.href = "./";
        }
        if (!cookieCutter.get('lang')) {
            cookieCutter.set('lang', 'E');
            lang = 'M';
        }
        const opendropdown = () => {
            this.setState({ open: !this.state.open })
        }
        const logout = () => {
            cookieCutter.set('userInfo', '', { expires: new Date(0) });
            window.location.href = "/";
        }

        return (
            <>
                {/* =============== Topbar area start =============== */}
                <div className="topbar-area">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-4 col-md-5 tob-contact-row">
                                <div className="topbar-contact">
                                    <ul>
                                        <li>
                                            <i className="bx bxs-phone" />
                                            <a href="tel:+17632275032">+91 9130901234</a>
                                        </li>

                                        <li>
                                            <i className="bx bxs-envelope" />
                                            <a href="mailto:info@example.com">snapcity@gmail.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-6">
                                <div className="topbar-social">
                                    <ul>
                                        <li>
                                            <a href={"https://instagram.com/snapcityinpune?igshid=YmMyMTA2M2Y="}><i className="bx bxl-instagram" /></a>
                                        </li>
                                        <li>
                                            <a href={"https://www.facebook.com/Snapcity-IN-PUNE-104475251191834"}><i className="bx bxl-facebook" /></a>
                                        </li>
                                        <li>
                                            <a href={"https://wa.me/message/XCXQSXICIZ2QG1"}><i className="bx bxl-whatsapp" /></a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12 col-12">

                                {!this.state.signin ? <>
                                    <div className="">
                                        {/* <Link to={`${process.env.PUBLIC_URL}/contact?type=signin`} className="btn-common-sm">{this.state.lang == 'M' ? 'लॉगिन' : "Make Remaining Payment"}</Link> */}
                                        {/* <Link  style={{ marginLeft: "10px" }} to={`${process.env.PUBLIC_URL}/contact?type=signup`} className="btn-common-sm">SignUp</Link> */}
                                    </div>
                                </> : <>
                                        <Popup trigger={<button className="btn btn-primary" type="button">
                                            LoginId: {this.state.userinfo?.PHONE_NO}
                                            {/* <i className="bx bx-user-pin" /> */}
                                        </button>} position="bottom">
                                            <div>
                                                <div className="account-dropdown activeCard">
                                                    <ul>
                                                        <li className="account-el">
                                                            <i className="bx bxs-user-account" />
                                                            <Link to={`${process.env.PUBLIC_URL}/myaccount`}>My Account</Link>
                                                        </li>

                                                        <li className="account-el">
                                                            <i className="bx bxs-user-account" />
                                                            <Link to={`${process.env.PUBLIC_URL}?lang=M`}>{this.state.lang == 'M' ? 'मराठी' : "Marathi"} </Link>
                                                        </li>
                                                        <li className="account-el">
                                                            <i className="bx bxs-user-account" />
                                                            <Link to={`${process.env.PUBLIC_URL}?lang=E`}>{this.state.lang == 'M' ? 'इंग्लिश' : "English"}</Link>
                                                        </li>
                                                        <li className="account-el">
                                                            <div onClick={logout}><i className="bx bx-log-in-circle" />Log out</div>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </Popup>
                                        <button className="btn btn-warning" style={{margin:"5px"}} type="button">
                                            <Link to={`${process.env.PUBLIC_URL}/myaccount`}>Pending Payment</Link>
                                        </button>

                                    </>}
                            </div>
                            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                                <Popup trigger={<button className="btn btn-primary" type="button">
                                    {this.state.lang == 'M' ? 'भाषा' : "Lang"} <i className="bx bx-user-pin" />
                                </button>} position="bottom">
                                    <div>
                                        <div className="account-dropdown activeCard">
                                            <ul>
                                                <li className="account-el">
                                                    <i className="bx bxs-user-account" />
                                                    <Link to={`${process.env.PUBLIC_URL}?lang=M`}>{this.state.lang == 'M' ? 'मराठी' : "Marathi"} </Link>
                                                </li>
                                                <li className="account-el">
                                                    <i className="bx bxs-user-account" />
                                                    <Link to={`${process.env.PUBLIC_URL}?lang=E`}>{this.state.lang == 'M' ? 'इंग्लिश' : "English"}</Link>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </Popup>

                            </div> */}

                        </div>
                    </div>
                </div>

                {/* =============== Topbar area end =============== */}


                {/* ===============  header area start =============== */}
                <header>
                    <div className="header-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                    <div className="navbar-wrap">
                                        <div className="logo d-flex justify-content-between logosize">
                                            <Link to={`${process.env.PUBLIC_URL}/`} className="navbar-brand" onClick={this.scrollTop}><img className="logosize" src={logoMain} alt="" /></Link>

                                        </div>
                                        {this.state.open &&
                                            <div className="account-dropdown activeCard">
                                                <ul>
                                                    <li className="account-el">
                                                        <i className="bx bxs-user-account" />
                                                        <Link to={"#"}>My Account</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bx-extension" />
                                                        <Link to={"#"}>Settings</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bx-log-in-circle" />
                                                        <Link to={"#"}>Log out</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12">


                                </div>
                            </div>
                        </div>

                        <form>
                            <div className="main-searchbar">
                                <div className="searchbar-close">
                                    <i className="bx bx-x" />
                                </div>
                                <input type="text" placeholder="Search Here......" />
                                <div className="searchbar-icon">
                                    <i className="bx bx-search" />
                                </div>
                            </div>
                        </form>

                    </div>
                </header>


                {/* ===============  header area end =============== */}
            </>
        );
    }
}

export default Headers;
